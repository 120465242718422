<template>
  <HoppButtonSecondary
    v-if="workspaceRole === TeamMemberRole.Owner"
    v-tippy="{ theme: 'tooltip' }"
    :title="t('team.delete_all_activity_logs')"
    :icon="IconTrash2"
    class="!text-red-500 !p-0 ml-3"
    @click="showConfirmModal = true"
  />

  <HoppSmartConfirmModal
    :show="showConfirmModal"
    :title="t('confirm.delete_all_activity_logs')"
    @hide-modal="showConfirmModal = false"
    @resolve="deleteAllLogs"
  />
</template>

<script setup lang="ts">
import { useI18n } from "@hoppscotch/common/composables/i18n"
import { useToast } from "@hoppscotch/common/composables/toast"
import { WorkspaceService } from "@hoppscotch/common/services/workspace.service"
import { useService } from "dioc/vue"
import * as TE from "fp-ts/TaskEither"
import { pipe } from "fp-ts/lib/function"
import { computed, ref } from "vue"
import IconTrash2 from "~icons/lucide/trash-2"
import { TeamMemberRole } from "@api/generated/graphql"
import { ActivityLogsService } from "../../../services/desktop/activity-logs.service"

const t = useI18n()
const toast = useToast()
const workspaceService = useService(WorkspaceService)
const activityLogsService = useService(ActivityLogsService)

const showConfirmModal = ref(false)

const workspaceID = computed(() =>
  workspaceService.currentWorkspace.value.type === "team"
    ? workspaceService.currentWorkspace.value.teamID
    : ""
)

const workspaceRole = computed(() =>
  workspaceService.currentWorkspace.value.type === "team"
    ? workspaceService.currentWorkspace.value.role
    : TeamMemberRole.Viewer
)

const deleteAllLogs = () => {
  pipe(
    activityLogsService.deleteAllActivityLogs(workspaceID.value),
    TE.match(
      () => {
        toast.error(t("error.delete_all_activity_logs"))
        showConfirmModal.value = false
      },
      () => {
        toast.success(t("team.successfully_deleted_all_activity_logs"))
        showConfirmModal.value = false
      }
    )
  )()
}
</script>

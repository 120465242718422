import {
  runGQLQuery,
  runGQLSubscription,
  runMutation,
} from "@hoppscotch/common/helpers/backend/GQLClient"
import { getI18n } from "@hoppscotch/common/modules/i18n"
import { HistoryUIProviderService } from "@hoppscotch/common/services/history-ui-provider.service"
import { Service } from "dioc"
import * as E from "fp-ts/Either"
import { ref } from "vue"
import {
  ActivityLogAddedDocument,
  ActivityLogAddedSubscription,
  ActivityLogAddedSubscriptionVariables,
  ActivityLogDeletedDocument,
  ActivityLogDeletedManyDocument,
  ActivityLogDeletedManySubscription,
  ActivityLogDeletedManySubscriptionVariables,
  ActivityLogDeletedSubscription,
  ActivityLogDeletedSubscriptionVariables,
  ActivityLogsDocument,
  ActivityLogsQuery,
  ActivityLogsQueryVariables,
  DeleteActivityLogDocument,
  DeleteActivityLogMutation,
  DeleteActivityLogMutationVariables,
  DeleteAllActivityLogsDocument,
  DeleteAllActivityLogsMutation,
  DeleteAllActivityLogsMutationVariables,
  ServiceStatus,
  WorkspaceActivityLoggingStatusDocument,
} from "../../api/generated/graphql"

export class ActivityLogsService extends Service {
  public static readonly ID = "ACTIVITY_LOGS_SERVICE"

  private readonly historyService = this.bind(HistoryUIProviderService)

  public readonly isActivityLogsListPopulated = ref(false)

  onServiceInit() {
    this.fetchActivityLogEnabledStatus()
  }

  private async fetchActivityLogEnabledStatus() {
    const result = await this.getActivityLogEnabledStatus()

    if (!result || E.isLeft(result)) return

    this.historyService.isEnabled.value =
      result.right.workspaceActivityLoggingStatus === ServiceStatus.Enable

    if (this.historyService.isEnabled.value) {
      this.historyService.historyUIProviderTitle.value = (
        t: ReturnType<typeof getI18n>
      ) => t("team.activity_logs")
    }
  }

  public getActivityLogEnabledStatus() {
    return runGQLQuery({
      query: WorkspaceActivityLoggingStatusDocument,
      variables: {},
    })
  }

  public getActivityLogs(teamID: string, cursor: string, take: number) {
    return runGQLQuery<ActivityLogsQuery, ActivityLogsQueryVariables, string>({
      query: ActivityLogsDocument,
      variables: {
        cursor,
        take,
        teamID,
      },
    })
  }

  public deleteActivityLog(teamID: string, activityLogID: string) {
    return runMutation<
      DeleteActivityLogMutation,
      DeleteActivityLogMutationVariables,
      ""
    >(DeleteActivityLogDocument, {
      teamID,
      activityLogID,
    })
  }

  public deleteAllActivityLogs(teamID: string) {
    return runMutation<
      DeleteAllActivityLogsMutation,
      DeleteAllActivityLogsMutationVariables,
      ""
    >(DeleteAllActivityLogsDocument, {
      teamID,
    })
  }

  public runActivityLogAddedSubscription(teamID: string) {
    return runGQLSubscription<
      ActivityLogAddedSubscription,
      ActivityLogAddedSubscriptionVariables,
      ""
    >({
      query: ActivityLogAddedDocument,
      variables: { teamID },
    })
  }

  public runActivityLogDeletedSubscription(teamID: string) {
    return runGQLSubscription<
      ActivityLogDeletedSubscription,
      ActivityLogDeletedSubscriptionVariables,
      ""
    >({
      query: ActivityLogDeletedDocument,
      variables: { teamID },
    })
  }

  public runActivityLogDeletedManySubscription(teamID: string) {
    return runGQLSubscription<
      ActivityLogDeletedManySubscription,
      ActivityLogDeletedManySubscriptionVariables,
      ""
    >({
      query: ActivityLogDeletedManyDocument,
      variables: { teamID },
    })
  }
}

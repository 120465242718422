import { getI18n } from "@hoppscotch/common/modules/i18n"
import {
  BANNER_PRIORITY_MEDIUM,
  BannerContent,
  BannerService,
  BannerType,
} from "@hoppscotch/common/services/banner.service"
import { getService } from "@hoppscotch/common/modules/dioc"
import { KernelInterceptorService } from "@hoppscotch/common/services/kernel-interceptor.service"
import { PersistenceService } from "@hoppscotch/common/services/persistence"
import { differenceInDays, parseISO } from "date-fns"
import { Service } from "dioc"
import * as E from "fp-ts/Either"
import { parseBodyAsJSON } from "@hoppscotch/common/helpers/functional/json"

const daysRemaining = (expiryDate: string) =>
  differenceInDays(parseISO(expiryDate), new Date())

/**
 * Returns the banner type based on the number of days remaining for the license to expire
 */
const getLicenseBannerType = (daysRemaining: number): BannerType => {
  if (daysRemaining <= 0) {
    return "error"
  }
  if (daysRemaining < 4) {
    return "warning"
  }
  return "info"
}

/**
 * Returns a banner to be displayed based on the license status
 */
const getLicenseBanner = (data: any): BannerContent => {
  const islicenseValid = data.status === "valid"
  return {
    type: getLicenseBannerType(
      islicenseValid ? daysRemaining(data.validUntilISO) : 0
    ),
    text: (t: ReturnType<typeof getI18n>) => {
      if (islicenseValid) {
        const days = daysRemaining(data.validUntilISO)
        const daysText =
          days === 1
            ? t("license.expiring_in_day")
            : t("license.expiring_in_days")

        return t("license.expiring", {
          days: `${days} ${daysText}`,
        })
      }
      return t("license.expired")
    },
    score: BANNER_PRIORITY_MEDIUM,
  }
}

/**
 * Service for managing licensing information.
 */
export class LicensingService extends Service {
  /**
   * The ID of the licensing service.
   */
  public static readonly ID = "LICENSING_SERVICE"

  private readonly banner = this.bind(BannerService)
  private readonly interceptorService = getService(KernelInterceptorService)
  private readonly persistenceService = getService(PersistenceService)

  onServiceInit() {
    this.getLicenseStatus()
  }

  /**
   * Retrieves the license status from the backend and displays a banner if the license is about to expire
   * or is expired
   */
  public async getLicenseStatus() {
    try {
      const accessToken =
        await this.persistenceService.getLocalConfig("access_token")

      if (!accessToken) {
        console.error("No access token available")
        return
      }

      const { response } = this.interceptorService.execute({
        id: Date.now(),
        url: `${import.meta.env.VITE_BACKEND_API_URL}/license/status`,
        version: "HTTP/1.1",
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      const responseData = await response

      if (E.isLeft(responseData)) {
        console.error("Failed to fetch license status")
        return
      }

      const parsedData = parseBodyAsJSON(responseData.right.body)

      if (parsedData._tag === "None") {
        console.error("Failed to parse license status response")
        return
      }

      const res = parsedData.value

      if (
        (res.status === "valid" && daysRemaining(res.validUntilISO) < 11) ||
        res.status === "valid_but_past_due"
      ) {
        this.banner.showBanner(getLicenseBanner(res))
      }
    } catch (error) {
      console.error(error)
    }
  }
}

import { save as Lt } from "@tauri-apps/plugin-dialog";
import { writeTextFile as Dt, writeFile as Vt } from "@tauri-apps/plugin-fs";
import { open as $t } from "@tauri-apps/plugin-shell";
import be from "axios";
function ct(t, e, r, n, s, a, i, o, c) {
  switch (arguments.length) {
    case 1:
      return t;
    case 2:
      return e(t);
    case 3:
      return r(e(t));
    case 4:
      return n(r(e(t)));
    case 5:
      return s(n(r(e(t))));
    case 6:
      return a(s(n(r(e(t)))));
    case 7:
      return i(a(s(n(r(e(t))))));
    case 8:
      return o(i(a(s(n(r(e(t)))))));
    case 9:
      return c(o(i(a(s(n(r(e(t))))))));
    default: {
      for (var d = arguments[0], p = 1; p < arguments.length; p++)
        d = arguments[p](d);
      return d;
    }
  }
}
var Ut = function(t) {
  return { _tag: "Left", left: t };
}, Mt = function(t) {
  return { _tag: "Right", right: t };
}, zt = function(t) {
  return t.length > 0;
}, Wt = function(t) {
  return t[0];
}, ut = Object.prototype.hasOwnProperty, Bt = zt, Kt = Wt, qe = Kt, Ht = function(t) {
  return t[t.length - 1];
}, ze = function(t) {
  return function(e) {
    var r = e.split(t);
    return Bt(r) ? r : [e];
  };
};
const Xt = {
  version: { major: 1, minor: 0, patch: 0 },
  api: {
    async saveFileWithDialog(t) {
      const e = new Blob([t.data], { type: t.contentType }), r = document.createElement("a"), n = URL.createObjectURL(e);
      return r.href = n, r.download = t.suggestedFilename ?? ct(
        n,
        ze("/"),
        Ht,
        ze("#"),
        qe,
        ze("?"),
        qe
      ), document.body.appendChild(r), r.click(), setTimeout(() => {
        document.body.removeChild(r), URL.revokeObjectURL(n);
      }, 1e3), { type: "unknown" };
    },
    async openExternalLink(t) {
      return window.open(t.url, "_blank"), { type: "unknown" };
    },
    async listen(t, e) {
      const r = (n) => {
        const s = window.location.hash;
        if (s && s.startsWith(`#${t}:`)) {
          const a = s.slice(t.length + 2);
          e({
            event: t,
            id: Date.now(),
            payload: JSON.parse(a)
          });
        }
      };
      return window.addEventListener("hashchange", r), () => window.removeEventListener("hashchange", r);
    },
    async once(t, e) {
      const r = (n) => {
        const s = window.location.hash;
        if (s && s.startsWith(`#${t}:`)) {
          const a = s.slice(t.length + 2);
          window.removeEventListener("hashchange", r), e({
            event: t,
            id: Date.now(),
            payload: JSON.parse(a)
          });
        }
      };
      return window.addEventListener("hashchange", r), () => window.removeEventListener("hashchange", r);
    },
    async emit(t, e) {
      window.location.hash = `${t}:${JSON.stringify(e)}`;
    }
  }
}, Ft = {
  v1: Xt
};
function Gt(t, e, r, n) {
  if (r === "a" && !n) throw new TypeError("Private accessor was defined without a getter");
  if (typeof e == "function" ? t !== e || !n : !e.has(t)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return r === "m" ? n : r === "a" ? n.call(t) : n ? n.value : e.get(t);
}
function Yt(t, e, r, n, s) {
  if (typeof e == "function" ? t !== e || !s : !e.has(t)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
  return e.set(t, r), r;
}
var Te;
function Jt(t, e = !1) {
  return window.__TAURI_INTERNALS__.transformCallback(t, e);
}
async function S(t, e = {}, r) {
  return window.__TAURI_INTERNALS__.invoke(t, e, r);
}
class Qt {
  get rid() {
    return Gt(this, Te, "f");
  }
  constructor(e) {
    Te.set(this, void 0), Yt(this, Te, e);
  }
  /**
   * Destroys and cleans up this resource from memory.
   * **You should not call any method on this object anymore and should drop any reference to it.**
   */
  async close() {
    return S("plugin:resources|close", {
      rid: this.rid
    });
  }
}
Te = /* @__PURE__ */ new WeakMap();
var et;
(function(t) {
  t.WINDOW_RESIZED = "tauri://resize", t.WINDOW_MOVED = "tauri://move", t.WINDOW_CLOSE_REQUESTED = "tauri://close-requested", t.WINDOW_DESTROYED = "tauri://destroyed", t.WINDOW_FOCUS = "tauri://focus", t.WINDOW_BLUR = "tauri://blur", t.WINDOW_SCALE_FACTOR_CHANGED = "tauri://scale-change", t.WINDOW_THEME_CHANGED = "tauri://theme-changed", t.WINDOW_CREATED = "tauri://window-created", t.WEBVIEW_CREATED = "tauri://webview-created", t.DRAG_ENTER = "tauri://drag-enter", t.DRAG_OVER = "tauri://drag-over", t.DRAG_DROP = "tauri://drag-drop", t.DRAG_LEAVE = "tauri://drag-leave";
})(et || (et = {}));
async function qt(t, e) {
  await S("plugin:event|unlisten", {
    event: t,
    eventId: e
  });
}
async function Oe(t, e, r) {
  var n;
  const s = (n = void 0) !== null && n !== void 0 ? n : { kind: "Any" };
  return S("plugin:event|listen", {
    event: t,
    target: s,
    handler: Jt(e)
  }).then((a) => async () => qt(t, a));
}
async function er(t, e) {
  await S("plugin:event|emit", {
    event: t,
    payload: e
  });
}
const tr = {
  version: { major: 1, minor: 0, patch: 0 },
  api: {
    async saveFileWithDialog(t) {
      const e = await Lt({
        filters: t.filters,
        defaultPath: t.suggestedFilename
      });
      return e ? (typeof t.data == "string" ? await Dt(e, t.data) : await Vt(e, t.data), { type: "saved", path: e }) : { type: "cancelled" };
    },
    async openExternalLink(t) {
      return await $t(t.url), { type: "opened" };
    },
    async listen(t, e) {
      return await Oe(t, e);
    },
    async once(t, e) {
      const r = await Oe(t, (n) => {
        r(), e(n);
      });
      return r;
    },
    async emit(t, e) {
      await er(t, e);
    }
  }
}, rr = {
  v1: tr
};
var w = Ut, O = Mt, dt = /* @__PURE__ */ ((t) => (t.TEXT_PLAIN = "text/plain", t.TEXT_HTML = "text/html", t.TEXT_CSS = "text/css", t.TEXT_CSV = "text/csv", t.APPLICATION_JSON = "application/json", t.APPLICATION_LD_JSON = "application/ld+json", t.APPLICATION_XML = "application/xml", t.TEXT_XML = "text/xml", t.APPLICATION_FORM = "application/x-www-form-urlencoded", t.APPLICATION_OCTET = "application/octet-stream", t.MULTIPART_FORM = "multipart/form-data", t))(dt || {});
const lt = {
  body: (t, e) => ({
    body: new Uint8Array(t),
    mediaType: typeof e == "string" ? Object.values(dt).find((r) => e.includes(r)) ?? "application/octet-stream" : e ?? "application/octet-stream"
    /* APPLICATION_OCTET */
  })
}, qn = {
  text: (t, e) => ({
    kind: "text",
    content: t,
    mediaType: e ?? "text/plain"
    /* TEXT_PLAIN */
  }),
  json: (t, e) => ({
    kind: "json",
    content: t,
    mediaType: e ?? "application/json"
    /* APPLICATION_JSON */
  }),
  xml: (t, e) => ({
    kind: "xml",
    content: t,
    mediaType: e ?? "application/xml"
    /* APPLICATION_XML */
  }),
  form: (t) => ({
    kind: "form",
    content: t,
    mediaType: "application/x-www-form-urlencoded"
    /* APPLICATION_FORM */
  }),
  binary: (t, e = "application/octet-stream", r) => ({
    kind: "binary",
    content: t,
    mediaType: e,
    filename: r
  }),
  multipart: (t) => ({
    kind: "multipart",
    content: t,
    mediaType: "multipart/form-data"
    /* MULTIPART_FORM */
  }),
  urlencoded: (t) => ({
    kind: "urlencoded",
    content: t,
    mediaType: "application/x-www-form-urlencoded"
    /* APPLICATION_FORM */
  }),
  stream: (t, e) => ({
    kind: "stream",
    content: t,
    mediaType: e
  })
};
function nr(t) {
  return function(e) {
    var r = {};
    for (var n in e)
      ut.call(e, n) && (r[n] = t(n, e[n]));
    return r;
  };
}
function sr(t) {
  return nr(function(e, r) {
    return t(r);
  });
}
function ar(t) {
  return function(e) {
    var r = {}, n = !1;
    for (var s in e)
      if (ut.call(e, s)) {
        var a = e[s];
        t(s, a) ? r[s] = a : n = !0;
      }
    return n ? r : e;
  };
}
var ir = sr;
function or(t) {
  return ar(t);
}
const cr = (t) => t >= 100 && t < 600, ur = (t) => ct(
  t,
  or((e, r) => r !== void 0),
  ir(String)
), dr = {
  version: { major: 1, minor: 0, patch: 0 },
  api: {
    id: "axios",
    capabilities: {
      method: /* @__PURE__ */ new Set([
        "GET",
        "POST",
        "PUT",
        "DELETE",
        "PATCH",
        "HEAD",
        "OPTIONS"
      ]),
      header: /* @__PURE__ */ new Set(["stringvalue", "arrayvalue", "multivalue"]),
      content: /* @__PURE__ */ new Set([
        "text",
        "json",
        "xml",
        "form",
        "urlencoded",
        "compression"
      ]),
      auth: /* @__PURE__ */ new Set(["basic", "bearer", "apikey", "aws"]),
      security: /* @__PURE__ */ new Set([]),
      proxy: /* @__PURE__ */ new Set([]),
      advanced: /* @__PURE__ */ new Set([])
    },
    canHandle(t) {
      var e;
      return this.capabilities.method.has(t.method) ? t.content && !this.capabilities.content.has(t.content.kind) ? w({
        kind: "unsupported_feature",
        feature: "content",
        message: `Content type ${t.content.kind} is not supported`,
        relay: "axios"
      }) : t.auth && !this.capabilities.auth.has(t.auth.kind) ? w({
        kind: "unsupported_feature",
        feature: "authentication",
        message: `Authentication type ${t.auth.kind} is not supported`,
        relay: "axios"
      }) : (e = t.security) != null && e.certificates ? w({
        kind: "unsupported_feature",
        feature: "security",
        message: "Client certificates are not supported",
        relay: "axios"
      }) : t.proxy ? w({
        kind: "unsupported_feature",
        feature: "proxy",
        message: "Proxy is not supported",
        relay: "axios"
      }) : O(!0) : w({
        kind: "unsupported_feature",
        feature: "method",
        message: `Method ${t.method} is not supported`,
        relay: "axios"
      });
    },
    execute(t) {
      const e = be.CancelToken.source(), r = {
        on: () => () => {
        },
        once: () => () => {
        },
        off: () => {
        }
      }, n = (async () => {
        var s, a, i, o, c, d, p, T, Q;
        try {
          const b = Date.now(), C = {
            url: t.url,
            method: t.method,
            headers: t.headers,
            params: t.params,
            data: (s = t.content) == null ? void 0 : s.content,
            maxRedirects: (i = (a = t.meta) == null ? void 0 : a.options) == null ? void 0 : i.maxRedirects,
            timeout: (c = (o = t.meta) == null ? void 0 : o.options) == null ? void 0 : c.timeout,
            decompress: ((p = (d = t.meta) == null ? void 0 : d.options) == null ? void 0 : p.decompress) ?? !0,
            validateStatus: null,
            cancelToken: e.token,
            responseType: "arraybuffer"
          }, I = await be(C), Me = Date.now();
          if (!cr(I.status))
            return w({
              kind: "version",
              message: `Invalid status code: ${I.status}`
            });
          const q = ur(I.headers), Pt = q["content-type"] || q["Content-Type"] || q["CONTENT-TYPE"], jt = {
            id: t.id,
            status: I.status,
            statusText: I.statusText,
            version: t.version,
            headers: q,
            body: lt.body(I.data, Pt),
            meta: {
              timing: {
                start: b,
                end: Me
              },
              size: {
                headers: JSON.stringify(I.headers).length,
                body: ((T = I.data) == null ? void 0 : T.length) ?? 0,
                total: JSON.stringify(I.headers).length + (((Q = I.data) == null ? void 0 : Q.length) ?? 0)
              }
            }
          };
          return O(jt);
        } catch (b) {
          return be.isCancel(b) ? w({ kind: "abort", message: "Request cancelled" }) : be.isAxiosError(b) ? b.code === "ECONNABORTED" ? w({
            kind: "timeout",
            message: "Request timed out",
            phase: "response"
          }) : w({
            kind: "network",
            message: b.message
          }) : w({
            kind: "network",
            message: b instanceof Error ? b.message : "Unknown error occurred",
            cause: b
          });
        }
      })();
      return {
        cancel: async () => {
          e.cancel();
        },
        emitter: r,
        response: n
      };
    }
  }
}, lr = {
  v1: dr
};
var tt;
(function(t) {
  t.TEXT_PLAIN = "text/plain", t.TEXT_HTML = "text/html", t.TEXT_CSS = "text/css", t.TEXT_CSV = "text/csv", t.APPLICATION_JSON = "application/json", t.APPLICATION_LD_JSON = "application/ld+json", t.APPLICATION_XML = "application/xml", t.TEXT_XML = "text/xml", t.APPLICATION_FORM = "application/x-www-form-urlencoded", t.APPLICATION_OCTET = "application/octet-stream", t.MULTIPART_FORM = "multipart/form-data";
})(tt || (tt = {}));
async function fr(t) {
  return await S("plugin:relay|execute", { request: t });
}
async function hr(t) {
  return await S("plugin:relay|cancel", { requestId: t });
}
const pr = {
  version: { major: 1, minor: 0, patch: 0 },
  api: {
    id: "desktop",
    capabilities: {
      method: /* @__PURE__ */ new Set([
        "GET",
        "POST",
        "PUT",
        "DELETE",
        "PATCH",
        "HEAD",
        "OPTIONS"
      ]),
      header: /* @__PURE__ */ new Set([
        "stringvalue",
        "arrayvalue",
        "multivalue"
      ]),
      content: /* @__PURE__ */ new Set([
        "text",
        "json",
        "xml",
        "form",
        "binary",
        "multipart",
        "urlencoded",
        "stream",
        "compression"
      ]),
      auth: /* @__PURE__ */ new Set([
        "basic",
        "bearer",
        "digest",
        "oauth2",
        "apikey"
      ]),
      security: /* @__PURE__ */ new Set([
        "clientcertificates",
        "cacertificates",
        "certificatevalidation",
        "hostverification",
        "peerverification"
      ]),
      proxy: /* @__PURE__ */ new Set([
        "http",
        "https",
        "authentication",
        "certificates"
      ]),
      advanced: /* @__PURE__ */ new Set([
        "retry",
        "redirects",
        "timeout",
        "cookies",
        "keepalive",
        "tcpoptions",
        "http2",
        "http3"
      ])
    },
    canHandle(t) {
      var e;
      return this.capabilities.method.has(t.method) ? t.content && !this.capabilities.content.has(t.content.kind) ? w({
        kind: "unsupported_feature",
        feature: "content",
        message: `Content type ${t.content.kind} is not supported`,
        relay: "desktop"
      }) : t.auth && !this.capabilities.auth.has(t.auth.kind) ? w({
        kind: "unsupported_feature",
        feature: "authentication",
        message: `Authentication type ${t.auth.kind} is not supported`,
        relay: "desktop"
      }) : (e = t.security) != null && e.certificates && !this.capabilities.security.has("clientcertificates") ? w({
        kind: "unsupported_feature",
        feature: "security",
        message: "Client certificates are not supported",
        relay: "desktop"
      }) : t.proxy && !this.capabilities.proxy.has(t.proxy.url.startsWith("https") ? "https" : "http") ? w({
        kind: "unsupported_feature",
        feature: "proxy",
        message: `Proxy protocol ${t.proxy.url.split(":")[0]} is not supported`,
        relay: "desktop"
      }) : O(!0) : w({
        kind: "unsupported_feature",
        feature: "method",
        message: `Method ${t.method} is not supported`,
        relay: "desktop"
      });
    },
    execute(t) {
      const e = {
        on: () => () => {
        },
        once: () => () => {
        },
        off: () => {
        }
      }, r = {
        id: t.id,
        url: t.url,
        method: t.method,
        version: t.version,
        headers: t.headers,
        params: t.params,
        content: t.content,
        auth: t.auth,
        security: t.security,
        proxy: t.proxy
      }, n = fr(r).then((s) => {
        if (s.kind === "success") {
          const a = {
            id: s.response.id,
            status: s.response.status,
            statusText: s.response.statusText,
            version: s.response.version,
            headers: s.response.headers,
            cookies: s.response.cookies,
            body: lt.body(s.response.body.body, s.response.body.mediaType),
            meta: {
              timing: {
                start: s.response.meta.timing.start,
                end: s.response.meta.timing.end
              },
              size: s.response.meta.size
            }
          };
          return O(a);
        }
        return w(s.error);
      }).catch((s) => {
        const a = {
          kind: "network",
          message: s instanceof Error ? s.message : "Unknown error occurred",
          cause: s
        };
        return w(a);
      });
      return {
        cancel: async () => {
          await hr(t.id);
        },
        emitter: e,
        response: n
      };
    }
  }
}, mr = {
  v1: pr
};
class yr {
  constructor() {
    this.keyToValue = /* @__PURE__ */ new Map(), this.valueToKey = /* @__PURE__ */ new Map();
  }
  set(e, r) {
    this.keyToValue.set(e, r), this.valueToKey.set(r, e);
  }
  getByKey(e) {
    return this.keyToValue.get(e);
  }
  getByValue(e) {
    return this.valueToKey.get(e);
  }
  clear() {
    this.keyToValue.clear(), this.valueToKey.clear();
  }
}
class ft {
  constructor(e) {
    this.generateIdentifier = e, this.kv = new yr();
  }
  register(e, r) {
    this.kv.getByValue(e) || (r || (r = this.generateIdentifier(e)), this.kv.set(r, e));
  }
  clear() {
    this.kv.clear();
  }
  getIdentifier(e) {
    return this.kv.getByValue(e);
  }
  getValue(e) {
    return this.kv.getByKey(e);
  }
}
class gr extends ft {
  constructor() {
    super((e) => e.name), this.classToAllowedProps = /* @__PURE__ */ new Map();
  }
  register(e, r) {
    typeof r == "object" ? (r.allowProps && this.classToAllowedProps.set(e, r.allowProps), super.register(e, r.identifier)) : super.register(e, r);
  }
  getAllowedProps(e) {
    return this.classToAllowedProps.get(e);
  }
}
function vr(t) {
  if ("values" in Object)
    return Object.values(t);
  const e = [];
  for (const r in t)
    t.hasOwnProperty(r) && e.push(t[r]);
  return e;
}
function _r(t, e) {
  const r = vr(t);
  if ("find" in r)
    return r.find(e);
  const n = r;
  for (let s = 0; s < n.length; s++) {
    const a = n[s];
    if (e(a))
      return a;
  }
}
function ne(t, e) {
  Object.entries(t).forEach(([r, n]) => e(n, r));
}
function Ee(t, e) {
  return t.indexOf(e) !== -1;
}
function rt(t, e) {
  for (let r = 0; r < t.length; r++) {
    const n = t[r];
    if (e(n))
      return n;
  }
}
class wr {
  constructor() {
    this.transfomers = {};
  }
  register(e) {
    this.transfomers[e.name] = e;
  }
  findApplicable(e) {
    return _r(this.transfomers, (r) => r.isApplicable(e));
  }
  findByName(e) {
    return this.transfomers[e];
  }
}
const kr = (t) => Object.prototype.toString.call(t).slice(8, -1), ht = (t) => typeof t > "u", xr = (t) => t === null, oe = (t) => typeof t != "object" || t === null || t === Object.prototype ? !1 : Object.getPrototypeOf(t) === null ? !0 : Object.getPrototypeOf(t) === Object.prototype, Ke = (t) => oe(t) && Object.keys(t).length === 0, B = (t) => Array.isArray(t), br = (t) => typeof t == "string", Tr = (t) => typeof t == "number" && !isNaN(t), Er = (t) => typeof t == "boolean", Sr = (t) => t instanceof RegExp, ce = (t) => t instanceof Map, ue = (t) => t instanceof Set, pt = (t) => kr(t) === "Symbol", Or = (t) => t instanceof Date && !isNaN(t.valueOf()), Ir = (t) => t instanceof Error, nt = (t) => typeof t == "number" && isNaN(t), Rr = (t) => Er(t) || xr(t) || ht(t) || Tr(t) || br(t) || pt(t), Nr = (t) => typeof t == "bigint", Ar = (t) => t === 1 / 0 || t === -1 / 0, Cr = (t) => ArrayBuffer.isView(t) && !(t instanceof DataView), Zr = (t) => t instanceof URL, mt = (t) => t.replace(/\./g, "\\."), We = (t) => t.map(String).map(mt).join("."), ie = (t) => {
  const e = [];
  let r = "";
  for (let s = 0; s < t.length; s++) {
    let a = t.charAt(s);
    if (a === "\\" && t.charAt(s + 1) === ".") {
      r += ".", s++;
      continue;
    }
    if (a === ".") {
      e.push(r), r = "";
      continue;
    }
    r += a;
  }
  const n = r;
  return e.push(n), e;
};
function V(t, e, r, n) {
  return {
    isApplicable: t,
    annotation: e,
    transform: r,
    untransform: n
  };
}
const yt = [
  V(ht, "undefined", () => null, () => {
  }),
  V(Nr, "bigint", (t) => t.toString(), (t) => typeof BigInt < "u" ? BigInt(t) : (console.error("Please add a BigInt polyfill."), t)),
  V(Or, "Date", (t) => t.toISOString(), (t) => new Date(t)),
  V(Ir, "Error", (t, e) => {
    const r = {
      name: t.name,
      message: t.message
    };
    return e.allowedErrorProps.forEach((n) => {
      r[n] = t[n];
    }), r;
  }, (t, e) => {
    const r = new Error(t.message);
    return r.name = t.name, r.stack = t.stack, e.allowedErrorProps.forEach((n) => {
      r[n] = t[n];
    }), r;
  }),
  V(Sr, "regexp", (t) => "" + t, (t) => {
    const e = t.slice(1, t.lastIndexOf("/")), r = t.slice(t.lastIndexOf("/") + 1);
    return new RegExp(e, r);
  }),
  V(
    ue,
    "set",
    // (sets only exist in es6+)
    // eslint-disable-next-line es5/no-es6-methods
    (t) => [...t.values()],
    (t) => new Set(t)
  ),
  V(ce, "map", (t) => [...t.entries()], (t) => new Map(t)),
  V((t) => nt(t) || Ar(t), "number", (t) => nt(t) ? "NaN" : t > 0 ? "Infinity" : "-Infinity", Number),
  V((t) => t === 0 && 1 / t === -1 / 0, "number", () => "-0", Number),
  V(Zr, "URL", (t) => t.toString(), (t) => new URL(t))
];
function $e(t, e, r, n) {
  return {
    isApplicable: t,
    annotation: e,
    transform: r,
    untransform: n
  };
}
const gt = $e((t, e) => pt(t) ? !!e.symbolRegistry.getIdentifier(t) : !1, (t, e) => ["symbol", e.symbolRegistry.getIdentifier(t)], (t) => t.description, (t, e, r) => {
  const n = r.symbolRegistry.getValue(e[1]);
  if (!n)
    throw new Error("Trying to deserialize unknown symbol");
  return n;
}), Pr = [
  Int8Array,
  Uint8Array,
  Int16Array,
  Uint16Array,
  Int32Array,
  Uint32Array,
  Float32Array,
  Float64Array,
  Uint8ClampedArray
].reduce((t, e) => (t[e.name] = e, t), {}), vt = $e(Cr, (t) => ["typed-array", t.constructor.name], (t) => [...t], (t, e) => {
  const r = Pr[e[1]];
  if (!r)
    throw new Error("Trying to deserialize unknown typed array");
  return new r(t);
});
function _t(t, e) {
  return t != null && t.constructor ? !!e.classRegistry.getIdentifier(t.constructor) : !1;
}
const wt = $e(_t, (t, e) => ["class", e.classRegistry.getIdentifier(t.constructor)], (t, e) => {
  const r = e.classRegistry.getAllowedProps(t.constructor);
  if (!r)
    return { ...t };
  const n = {};
  return r.forEach((s) => {
    n[s] = t[s];
  }), n;
}, (t, e, r) => {
  const n = r.classRegistry.getValue(e[1]);
  if (!n)
    throw new Error(`Trying to deserialize unknown class '${e[1]}' - check https://github.com/blitz-js/superjson/issues/116#issuecomment-773996564`);
  return Object.assign(Object.create(n.prototype), t);
}), kt = $e((t, e) => !!e.customTransformerRegistry.findApplicable(t), (t, e) => ["custom", e.customTransformerRegistry.findApplicable(t).name], (t, e) => e.customTransformerRegistry.findApplicable(t).serialize(t), (t, e, r) => {
  const n = r.customTransformerRegistry.findByName(e[1]);
  if (!n)
    throw new Error("Trying to deserialize unknown custom value");
  return n.deserialize(t);
}), jr = [wt, gt, kt, vt], st = (t, e) => {
  const r = rt(jr, (s) => s.isApplicable(t, e));
  if (r)
    return {
      value: r.transform(t, e),
      type: r.annotation(t, e)
    };
  const n = rt(yt, (s) => s.isApplicable(t, e));
  if (n)
    return {
      value: n.transform(t, e),
      type: n.annotation
    };
}, xt = {};
yt.forEach((t) => {
  xt[t.annotation] = t;
});
const Lr = (t, e, r) => {
  if (B(e))
    switch (e[0]) {
      case "symbol":
        return gt.untransform(t, e, r);
      case "class":
        return wt.untransform(t, e, r);
      case "custom":
        return kt.untransform(t, e, r);
      case "typed-array":
        return vt.untransform(t, e, r);
      default:
        throw new Error("Unknown transformation: " + e);
    }
  else {
    const n = xt[e];
    if (!n)
      throw new Error("Unknown transformation: " + e);
    return n.untransform(t, r);
  }
}, te = (t, e) => {
  if (e > t.size)
    throw new Error("index out of bounds");
  const r = t.keys();
  for (; e > 0; )
    r.next(), e--;
  return r.next().value;
};
function bt(t) {
  if (Ee(t, "__proto__"))
    throw new Error("__proto__ is not allowed as a property");
  if (Ee(t, "prototype"))
    throw new Error("prototype is not allowed as a property");
  if (Ee(t, "constructor"))
    throw new Error("constructor is not allowed as a property");
}
const Dr = (t, e) => {
  bt(e);
  for (let r = 0; r < e.length; r++) {
    const n = e[r];
    if (ue(t))
      t = te(t, +n);
    else if (ce(t)) {
      const s = +n, a = +e[++r] == 0 ? "key" : "value", i = te(t, s);
      switch (a) {
        case "key":
          t = i;
          break;
        case "value":
          t = t.get(i);
          break;
      }
    } else
      t = t[n];
  }
  return t;
}, He = (t, e, r) => {
  if (bt(e), e.length === 0)
    return r(t);
  let n = t;
  for (let a = 0; a < e.length - 1; a++) {
    const i = e[a];
    if (B(n)) {
      const o = +i;
      n = n[o];
    } else if (oe(n))
      n = n[i];
    else if (ue(n)) {
      const o = +i;
      n = te(n, o);
    } else if (ce(n)) {
      if (a === e.length - 2)
        break;
      const c = +i, d = +e[++a] == 0 ? "key" : "value", p = te(n, c);
      switch (d) {
        case "key":
          n = p;
          break;
        case "value":
          n = n.get(p);
          break;
      }
    }
  }
  const s = e[e.length - 1];
  if (B(n) ? n[+s] = r(n[+s]) : oe(n) && (n[s] = r(n[s])), ue(n)) {
    const a = te(n, +s), i = r(a);
    a !== i && (n.delete(a), n.add(i));
  }
  if (ce(n)) {
    const a = +e[e.length - 2], i = te(n, a);
    switch (+s == 0 ? "key" : "value") {
      case "key": {
        const c = r(i);
        n.set(c, n.get(i)), c !== i && n.delete(i);
        break;
      }
      case "value": {
        n.set(i, r(n.get(i)));
        break;
      }
    }
  }
  return t;
};
function Xe(t, e, r = []) {
  if (!t)
    return;
  if (!B(t)) {
    ne(t, (a, i) => Xe(a, e, [...r, ...ie(i)]));
    return;
  }
  const [n, s] = t;
  s && ne(s, (a, i) => {
    Xe(a, e, [...r, ...ie(i)]);
  }), e(n, r);
}
function Vr(t, e, r) {
  return Xe(e, (n, s) => {
    t = He(t, s, (a) => Lr(a, n, r));
  }), t;
}
function $r(t, e) {
  function r(n, s) {
    const a = Dr(t, ie(s));
    n.map(ie).forEach((i) => {
      t = He(t, i, () => a);
    });
  }
  if (B(e)) {
    const [n, s] = e;
    n.forEach((a) => {
      t = He(t, ie(a), () => t);
    }), s && ne(s, r);
  } else
    ne(e, r);
  return t;
}
const Ur = (t, e) => oe(t) || B(t) || ce(t) || ue(t) || _t(t, e);
function Mr(t, e, r) {
  const n = r.get(t);
  n ? n.push(e) : r.set(t, [e]);
}
function zr(t, e) {
  const r = {};
  let n;
  return t.forEach((s) => {
    if (s.length <= 1)
      return;
    e || (s = s.map((o) => o.map(String)).sort((o, c) => o.length - c.length));
    const [a, ...i] = s;
    a.length === 0 ? n = i.map(We) : r[We(a)] = i.map(We);
  }), n ? Ke(r) ? [n] : [n, r] : Ke(r) ? void 0 : r;
}
const Tt = (t, e, r, n, s = [], a = [], i = /* @__PURE__ */ new Map()) => {
  const o = Rr(t);
  if (!o) {
    Mr(t, s, e);
    const b = i.get(t);
    if (b)
      return n ? {
        transformedValue: null
      } : b;
  }
  if (!Ur(t, r)) {
    const b = st(t, r), C = b ? {
      transformedValue: b.value,
      annotations: [b.type]
    } : {
      transformedValue: t
    };
    return o || i.set(t, C), C;
  }
  if (Ee(a, t))
    return {
      transformedValue: null
    };
  const c = st(t, r), d = (c == null ? void 0 : c.value) ?? t, p = B(d) ? [] : {}, T = {};
  ne(d, (b, C) => {
    if (C === "__proto__" || C === "constructor" || C === "prototype")
      throw new Error(`Detected property ${C}. This is a prototype pollution risk, please remove it from your object.`);
    const I = Tt(b, e, r, n, [...s, C], [...a, t], i);
    p[C] = I.transformedValue, B(I.annotations) ? T[C] = I.annotations : oe(I.annotations) && ne(I.annotations, (Me, q) => {
      T[mt(C) + "." + q] = Me;
    });
  });
  const Q = Ke(T) ? {
    transformedValue: p,
    annotations: c ? [c.type] : void 0
  } : {
    transformedValue: p,
    annotations: c ? [c.type, T] : T
  };
  return o || i.set(t, Q), Q;
};
function Et(t) {
  return Object.prototype.toString.call(t).slice(8, -1);
}
function at(t) {
  return Et(t) === "Array";
}
function Wr(t) {
  if (Et(t) !== "Object")
    return !1;
  const e = Object.getPrototypeOf(t);
  return !!e && e.constructor === Object && e === Object.prototype;
}
function Br(t, e, r, n, s) {
  const a = {}.propertyIsEnumerable.call(n, e) ? "enumerable" : "nonenumerable";
  a === "enumerable" && (t[e] = r), s && a === "nonenumerable" && Object.defineProperty(t, e, {
    value: r,
    enumerable: !1,
    writable: !0,
    configurable: !0
  });
}
function Fe(t, e = {}) {
  if (at(t))
    return t.map((s) => Fe(s, e));
  if (!Wr(t))
    return t;
  const r = Object.getOwnPropertyNames(t), n = Object.getOwnPropertySymbols(t);
  return [...r, ...n].reduce((s, a) => {
    if (at(e.props) && !e.props.includes(a))
      return s;
    const i = t[a], o = Fe(i, e);
    return Br(s, a, o, t, e.nonenumerable), s;
  }, {});
}
class _ {
  /**
   * @param dedupeReferentialEqualities  If true, SuperJSON will make sure only one instance of referentially equal objects are serialized and the rest are replaced with `null`.
   */
  constructor({ dedupe: e = !1 } = {}) {
    this.classRegistry = new gr(), this.symbolRegistry = new ft((r) => r.description ?? ""), this.customTransformerRegistry = new wr(), this.allowedErrorProps = [], this.dedupe = e;
  }
  serialize(e) {
    const r = /* @__PURE__ */ new Map(), n = Tt(e, r, this, this.dedupe), s = {
      json: n.transformedValue
    };
    n.annotations && (s.meta = {
      ...s.meta,
      values: n.annotations
    });
    const a = zr(r, this.dedupe);
    return a && (s.meta = {
      ...s.meta,
      referentialEqualities: a
    }), s;
  }
  deserialize(e) {
    const { json: r, meta: n } = e;
    let s = Fe(r);
    return n != null && n.values && (s = Vr(s, n.values, this)), n != null && n.referentialEqualities && (s = $r(s, n.referentialEqualities)), s;
  }
  stringify(e) {
    return JSON.stringify(this.serialize(e));
  }
  parse(e) {
    return this.deserialize(JSON.parse(e));
  }
  registerClass(e, r) {
    this.classRegistry.register(e, r);
  }
  registerSymbol(e, r) {
    this.symbolRegistry.register(e, r);
  }
  registerCustom(e, r) {
    this.customTransformerRegistry.register({
      name: r,
      ...e
    });
  }
  allowErrorProps(...e) {
    this.allowedErrorProps.push(...e);
  }
}
_.defaultInstance = new _();
_.serialize = _.defaultInstance.serialize.bind(_.defaultInstance);
_.deserialize = _.defaultInstance.deserialize.bind(_.defaultInstance);
_.stringify = _.defaultInstance.stringify.bind(_.defaultInstance);
_.parse = _.defaultInstance.parse.bind(_.defaultInstance);
_.registerClass = _.defaultInstance.registerClass.bind(_.defaultInstance);
_.registerSymbol = _.defaultInstance.registerSymbol.bind(_.defaultInstance);
_.registerCustom = _.defaultInstance.registerCustom.bind(_.defaultInstance);
_.allowErrorProps = _.defaultInstance.allowErrorProps.bind(_.defaultInstance);
_.serialize;
_.deserialize;
_.stringify;
_.parse;
_.registerClass;
_.registerCustom;
_.registerSymbol;
_.allowErrorProps;
var k;
(function(t) {
  t.assertEqual = (s) => s;
  function e(s) {
  }
  t.assertIs = e;
  function r(s) {
    throw new Error();
  }
  t.assertNever = r, t.arrayToEnum = (s) => {
    const a = {};
    for (const i of s)
      a[i] = i;
    return a;
  }, t.getValidEnumValues = (s) => {
    const a = t.objectKeys(s).filter((o) => typeof s[s[o]] != "number"), i = {};
    for (const o of a)
      i[o] = s[o];
    return t.objectValues(i);
  }, t.objectValues = (s) => t.objectKeys(s).map(function(a) {
    return s[a];
  }), t.objectKeys = typeof Object.keys == "function" ? (s) => Object.keys(s) : (s) => {
    const a = [];
    for (const i in s)
      Object.prototype.hasOwnProperty.call(s, i) && a.push(i);
    return a;
  }, t.find = (s, a) => {
    for (const i of s)
      if (a(i))
        return i;
  }, t.isInteger = typeof Number.isInteger == "function" ? (s) => Number.isInteger(s) : (s) => typeof s == "number" && isFinite(s) && Math.floor(s) === s;
  function n(s, a = " | ") {
    return s.map((i) => typeof i == "string" ? `'${i}'` : i).join(a);
  }
  t.joinValues = n, t.jsonStringifyReplacer = (s, a) => typeof a == "bigint" ? a.toString() : a;
})(k || (k = {}));
var Ge;
(function(t) {
  t.mergeShapes = (e, r) => ({
    ...e,
    ...r
    // second overwrites first
  });
})(Ge || (Ge = {}));
const l = k.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]), W = (t) => {
  switch (typeof t) {
    case "undefined":
      return l.undefined;
    case "string":
      return l.string;
    case "number":
      return isNaN(t) ? l.nan : l.number;
    case "boolean":
      return l.boolean;
    case "function":
      return l.function;
    case "bigint":
      return l.bigint;
    case "symbol":
      return l.symbol;
    case "object":
      return Array.isArray(t) ? l.array : t === null ? l.null : t.then && typeof t.then == "function" && t.catch && typeof t.catch == "function" ? l.promise : typeof Map < "u" && t instanceof Map ? l.map : typeof Set < "u" && t instanceof Set ? l.set : typeof Date < "u" && t instanceof Date ? l.date : l.object;
    default:
      return l.unknown;
  }
}, u = k.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]), Kr = (t) => JSON.stringify(t, null, 2).replace(/"([^"]+)":/g, "$1:");
class j extends Error {
  constructor(e) {
    super(), this.issues = [], this.addIssue = (n) => {
      this.issues = [...this.issues, n];
    }, this.addIssues = (n = []) => {
      this.issues = [...this.issues, ...n];
    };
    const r = new.target.prototype;
    Object.setPrototypeOf ? Object.setPrototypeOf(this, r) : this.__proto__ = r, this.name = "ZodError", this.issues = e;
  }
  get errors() {
    return this.issues;
  }
  format(e) {
    const r = e || function(a) {
      return a.message;
    }, n = { _errors: [] }, s = (a) => {
      for (const i of a.issues)
        if (i.code === "invalid_union")
          i.unionErrors.map(s);
        else if (i.code === "invalid_return_type")
          s(i.returnTypeError);
        else if (i.code === "invalid_arguments")
          s(i.argumentsError);
        else if (i.path.length === 0)
          n._errors.push(r(i));
        else {
          let o = n, c = 0;
          for (; c < i.path.length; ) {
            const d = i.path[c];
            c === i.path.length - 1 ? (o[d] = o[d] || { _errors: [] }, o[d]._errors.push(r(i))) : o[d] = o[d] || { _errors: [] }, o = o[d], c++;
          }
        }
    };
    return s(this), n;
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, k.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(e = (r) => r.message) {
    const r = {}, n = [];
    for (const s of this.issues)
      s.path.length > 0 ? (r[s.path[0]] = r[s.path[0]] || [], r[s.path[0]].push(e(s))) : n.push(e(s));
    return { formErrors: n, fieldErrors: r };
  }
  get formErrors() {
    return this.flatten();
  }
}
j.create = (t) => new j(t);
const de = (t, e) => {
  let r;
  switch (t.code) {
    case u.invalid_type:
      t.received === l.undefined ? r = "Required" : r = `Expected ${t.expected}, received ${t.received}`;
      break;
    case u.invalid_literal:
      r = `Invalid literal value, expected ${JSON.stringify(t.expected, k.jsonStringifyReplacer)}`;
      break;
    case u.unrecognized_keys:
      r = `Unrecognized key(s) in object: ${k.joinValues(t.keys, ", ")}`;
      break;
    case u.invalid_union:
      r = "Invalid input";
      break;
    case u.invalid_union_discriminator:
      r = `Invalid discriminator value. Expected ${k.joinValues(t.options)}`;
      break;
    case u.invalid_enum_value:
      r = `Invalid enum value. Expected ${k.joinValues(t.options)}, received '${t.received}'`;
      break;
    case u.invalid_arguments:
      r = "Invalid function arguments";
      break;
    case u.invalid_return_type:
      r = "Invalid function return type";
      break;
    case u.invalid_date:
      r = "Invalid date";
      break;
    case u.invalid_string:
      typeof t.validation == "object" ? "includes" in t.validation ? (r = `Invalid input: must include "${t.validation.includes}"`, typeof t.validation.position == "number" && (r = `${r} at one or more positions greater than or equal to ${t.validation.position}`)) : "startsWith" in t.validation ? r = `Invalid input: must start with "${t.validation.startsWith}"` : "endsWith" in t.validation ? r = `Invalid input: must end with "${t.validation.endsWith}"` : k.assertNever(t.validation) : t.validation !== "regex" ? r = `Invalid ${t.validation}` : r = "Invalid";
      break;
    case u.too_small:
      t.type === "array" ? r = `Array must contain ${t.exact ? "exactly" : t.inclusive ? "at least" : "more than"} ${t.minimum} element(s)` : t.type === "string" ? r = `String must contain ${t.exact ? "exactly" : t.inclusive ? "at least" : "over"} ${t.minimum} character(s)` : t.type === "number" ? r = `Number must be ${t.exact ? "exactly equal to " : t.inclusive ? "greater than or equal to " : "greater than "}${t.minimum}` : t.type === "date" ? r = `Date must be ${t.exact ? "exactly equal to " : t.inclusive ? "greater than or equal to " : "greater than "}${new Date(Number(t.minimum))}` : r = "Invalid input";
      break;
    case u.too_big:
      t.type === "array" ? r = `Array must contain ${t.exact ? "exactly" : t.inclusive ? "at most" : "less than"} ${t.maximum} element(s)` : t.type === "string" ? r = `String must contain ${t.exact ? "exactly" : t.inclusive ? "at most" : "under"} ${t.maximum} character(s)` : t.type === "number" ? r = `Number must be ${t.exact ? "exactly" : t.inclusive ? "less than or equal to" : "less than"} ${t.maximum}` : t.type === "bigint" ? r = `BigInt must be ${t.exact ? "exactly" : t.inclusive ? "less than or equal to" : "less than"} ${t.maximum}` : t.type === "date" ? r = `Date must be ${t.exact ? "exactly" : t.inclusive ? "smaller than or equal to" : "smaller than"} ${new Date(Number(t.maximum))}` : r = "Invalid input";
      break;
    case u.custom:
      r = "Invalid input";
      break;
    case u.invalid_intersection_types:
      r = "Intersection results could not be merged";
      break;
    case u.not_multiple_of:
      r = `Number must be a multiple of ${t.multipleOf}`;
      break;
    case u.not_finite:
      r = "Number must be finite";
      break;
    default:
      r = e.defaultError, k.assertNever(t);
  }
  return { message: r };
};
let St = de;
function Hr(t) {
  St = t;
}
function Ie() {
  return St;
}
const Re = (t) => {
  const { data: e, path: r, errorMaps: n, issueData: s } = t, a = [...r, ...s.path || []], i = {
    ...s,
    path: a
  };
  let o = "";
  const c = n.filter((d) => !!d).slice().reverse();
  for (const d of c)
    o = d(i, { data: e, defaultError: o }).message;
  return {
    ...s,
    path: a,
    message: s.message || o
  };
}, Xr = [];
function f(t, e) {
  const r = Re({
    issueData: e,
    data: t.data,
    path: t.path,
    errorMaps: [
      t.common.contextualErrorMap,
      t.schemaErrorMap,
      Ie(),
      de
      // then global default map
    ].filter((n) => !!n)
  });
  t.common.issues.push(r);
}
class R {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    this.value === "valid" && (this.value = "dirty");
  }
  abort() {
    this.value !== "aborted" && (this.value = "aborted");
  }
  static mergeArray(e, r) {
    const n = [];
    for (const s of r) {
      if (s.status === "aborted")
        return y;
      s.status === "dirty" && e.dirty(), n.push(s.value);
    }
    return { status: e.value, value: n };
  }
  static async mergeObjectAsync(e, r) {
    const n = [];
    for (const s of r)
      n.push({
        key: await s.key,
        value: await s.value
      });
    return R.mergeObjectSync(e, n);
  }
  static mergeObjectSync(e, r) {
    const n = {};
    for (const s of r) {
      const { key: a, value: i } = s;
      if (a.status === "aborted" || i.status === "aborted")
        return y;
      a.status === "dirty" && e.dirty(), i.status === "dirty" && e.dirty(), a.value !== "__proto__" && (typeof i.value < "u" || s.alwaysSet) && (n[a.value] = i.value);
    }
    return { status: e.value, value: n };
  }
}
const y = Object.freeze({
  status: "aborted"
}), Ot = (t) => ({ status: "dirty", value: t }), N = (t) => ({ status: "valid", value: t }), Ye = (t) => t.status === "aborted", Je = (t) => t.status === "dirty", le = (t) => t.status === "valid", Ne = (t) => typeof Promise < "u" && t instanceof Promise;
var h;
(function(t) {
  t.errToObj = (e) => typeof e == "string" ? { message: e } : e || {}, t.toString = (e) => typeof e == "string" ? e : e == null ? void 0 : e.message;
})(h || (h = {}));
class $ {
  constructor(e, r, n, s) {
    this._cachedPath = [], this.parent = e, this.data = r, this._path = n, this._key = s;
  }
  get path() {
    return this._cachedPath.length || (this._key instanceof Array ? this._cachedPath.push(...this._path, ...this._key) : this._cachedPath.push(...this._path, this._key)), this._cachedPath;
  }
}
const it = (t, e) => {
  if (le(e))
    return { success: !0, data: e.value };
  if (!t.common.issues.length)
    throw new Error("Validation failed but no issues detected.");
  return {
    success: !1,
    get error() {
      if (this._error)
        return this._error;
      const r = new j(t.common.issues);
      return this._error = r, this._error;
    }
  };
};
function g(t) {
  if (!t)
    return {};
  const { errorMap: e, invalid_type_error: r, required_error: n, description: s } = t;
  if (e && (r || n))
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  return e ? { errorMap: e, description: s } : { errorMap: (i, o) => i.code !== "invalid_type" ? { message: o.defaultError } : typeof o.data > "u" ? { message: n ?? o.defaultError } : { message: r ?? o.defaultError }, description: s };
}
class v {
  constructor(e) {
    this.spa = this.safeParseAsync, this._def = e, this.parse = this.parse.bind(this), this.safeParse = this.safeParse.bind(this), this.parseAsync = this.parseAsync.bind(this), this.safeParseAsync = this.safeParseAsync.bind(this), this.spa = this.spa.bind(this), this.refine = this.refine.bind(this), this.refinement = this.refinement.bind(this), this.superRefine = this.superRefine.bind(this), this.optional = this.optional.bind(this), this.nullable = this.nullable.bind(this), this.nullish = this.nullish.bind(this), this.array = this.array.bind(this), this.promise = this.promise.bind(this), this.or = this.or.bind(this), this.and = this.and.bind(this), this.transform = this.transform.bind(this), this.brand = this.brand.bind(this), this.default = this.default.bind(this), this.catch = this.catch.bind(this), this.describe = this.describe.bind(this), this.pipe = this.pipe.bind(this), this.readonly = this.readonly.bind(this), this.isNullable = this.isNullable.bind(this), this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(e) {
    return W(e.data);
  }
  _getOrReturnCtx(e, r) {
    return r || {
      common: e.parent.common,
      data: e.data,
      parsedType: W(e.data),
      schemaErrorMap: this._def.errorMap,
      path: e.path,
      parent: e.parent
    };
  }
  _processInputParams(e) {
    return {
      status: new R(),
      ctx: {
        common: e.parent.common,
        data: e.data,
        parsedType: W(e.data),
        schemaErrorMap: this._def.errorMap,
        path: e.path,
        parent: e.parent
      }
    };
  }
  _parseSync(e) {
    const r = this._parse(e);
    if (Ne(r))
      throw new Error("Synchronous parse encountered promise.");
    return r;
  }
  _parseAsync(e) {
    const r = this._parse(e);
    return Promise.resolve(r);
  }
  parse(e, r) {
    const n = this.safeParse(e, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  safeParse(e, r) {
    var n;
    const s = {
      common: {
        issues: [],
        async: (n = r == null ? void 0 : r.async) !== null && n !== void 0 ? n : !1,
        contextualErrorMap: r == null ? void 0 : r.errorMap
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: e,
      parsedType: W(e)
    }, a = this._parseSync({ data: e, path: s.path, parent: s });
    return it(s, a);
  }
  async parseAsync(e, r) {
    const n = await this.safeParseAsync(e, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  async safeParseAsync(e, r) {
    const n = {
      common: {
        issues: [],
        contextualErrorMap: r == null ? void 0 : r.errorMap,
        async: !0
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: e,
      parsedType: W(e)
    }, s = this._parse({ data: e, path: n.path, parent: n }), a = await (Ne(s) ? s : Promise.resolve(s));
    return it(n, a);
  }
  refine(e, r) {
    const n = (s) => typeof r == "string" || typeof r > "u" ? { message: r } : typeof r == "function" ? r(s) : r;
    return this._refinement((s, a) => {
      const i = e(s), o = () => a.addIssue({
        code: u.custom,
        ...n(s)
      });
      return typeof Promise < "u" && i instanceof Promise ? i.then((c) => c ? !0 : (o(), !1)) : i ? !0 : (o(), !1);
    });
  }
  refinement(e, r) {
    return this._refinement((n, s) => e(n) ? !0 : (s.addIssue(typeof r == "function" ? r(n, s) : r), !1));
  }
  _refinement(e) {
    return new D({
      schema: this,
      typeName: m.ZodEffects,
      effect: { type: "refinement", refinement: e }
    });
  }
  superRefine(e) {
    return this._refinement(e);
  }
  optional() {
    return M.create(this, this._def);
  }
  nullable() {
    return J.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return L.create(this, this._def);
  }
  promise() {
    return ae.create(this, this._def);
  }
  or(e) {
    return me.create([this, e], this._def);
  }
  and(e) {
    return ye.create(this, e, this._def);
  }
  transform(e) {
    return new D({
      ...g(this._def),
      schema: this,
      typeName: m.ZodEffects,
      effect: { type: "transform", transform: e }
    });
  }
  default(e) {
    const r = typeof e == "function" ? e : () => e;
    return new ke({
      ...g(this._def),
      innerType: this,
      defaultValue: r,
      typeName: m.ZodDefault
    });
  }
  brand() {
    return new Rt({
      typeName: m.ZodBranded,
      type: this,
      ...g(this._def)
    });
  }
  catch(e) {
    const r = typeof e == "function" ? e : () => e;
    return new Pe({
      ...g(this._def),
      innerType: this,
      catchValue: r,
      typeName: m.ZodCatch
    });
  }
  describe(e) {
    const r = this.constructor;
    return new r({
      ...this._def,
      description: e
    });
  }
  pipe(e) {
    return xe.create(this, e);
  }
  readonly() {
    return Le.create(this);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
}
const Fr = /^c[^\s-]{8,}$/i, Gr = /^[a-z][a-z0-9]*$/, Yr = /^[0-9A-HJKMNP-TV-Z]{26}$/, Jr = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i, Qr = /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i, qr = "^(\\p{Extended_Pictographic}|\\p{Emoji_Component})+$";
let Be;
const en = /^(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))$/, tn = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/, rn = (t) => t.precision ? t.offset ? new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${t.precision}}(([+-]\\d{2}(:?\\d{2})?)|Z)$`) : new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${t.precision}}Z$`) : t.precision === 0 ? t.offset ? new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(([+-]\\d{2}(:?\\d{2})?)|Z)$") : new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z$") : t.offset ? new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?(([+-]\\d{2}(:?\\d{2})?)|Z)$") : new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?Z$");
function nn(t, e) {
  return !!((e === "v4" || !e) && en.test(t) || (e === "v6" || !e) && tn.test(t));
}
class P extends v {
  _parse(e) {
    if (this._def.coerce && (e.data = String(e.data)), this._getType(e) !== l.string) {
      const a = this._getOrReturnCtx(e);
      return f(
        a,
        {
          code: u.invalid_type,
          expected: l.string,
          received: a.parsedType
        }
        //
      ), y;
    }
    const n = new R();
    let s;
    for (const a of this._def.checks)
      if (a.kind === "min")
        e.data.length < a.value && (s = this._getOrReturnCtx(e, s), f(s, {
          code: u.too_small,
          minimum: a.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: a.message
        }), n.dirty());
      else if (a.kind === "max")
        e.data.length > a.value && (s = this._getOrReturnCtx(e, s), f(s, {
          code: u.too_big,
          maximum: a.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: a.message
        }), n.dirty());
      else if (a.kind === "length") {
        const i = e.data.length > a.value, o = e.data.length < a.value;
        (i || o) && (s = this._getOrReturnCtx(e, s), i ? f(s, {
          code: u.too_big,
          maximum: a.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: a.message
        }) : o && f(s, {
          code: u.too_small,
          minimum: a.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: a.message
        }), n.dirty());
      } else if (a.kind === "email")
        Qr.test(e.data) || (s = this._getOrReturnCtx(e, s), f(s, {
          validation: "email",
          code: u.invalid_string,
          message: a.message
        }), n.dirty());
      else if (a.kind === "emoji")
        Be || (Be = new RegExp(qr, "u")), Be.test(e.data) || (s = this._getOrReturnCtx(e, s), f(s, {
          validation: "emoji",
          code: u.invalid_string,
          message: a.message
        }), n.dirty());
      else if (a.kind === "uuid")
        Jr.test(e.data) || (s = this._getOrReturnCtx(e, s), f(s, {
          validation: "uuid",
          code: u.invalid_string,
          message: a.message
        }), n.dirty());
      else if (a.kind === "cuid")
        Fr.test(e.data) || (s = this._getOrReturnCtx(e, s), f(s, {
          validation: "cuid",
          code: u.invalid_string,
          message: a.message
        }), n.dirty());
      else if (a.kind === "cuid2")
        Gr.test(e.data) || (s = this._getOrReturnCtx(e, s), f(s, {
          validation: "cuid2",
          code: u.invalid_string,
          message: a.message
        }), n.dirty());
      else if (a.kind === "ulid")
        Yr.test(e.data) || (s = this._getOrReturnCtx(e, s), f(s, {
          validation: "ulid",
          code: u.invalid_string,
          message: a.message
        }), n.dirty());
      else if (a.kind === "url")
        try {
          new URL(e.data);
        } catch {
          s = this._getOrReturnCtx(e, s), f(s, {
            validation: "url",
            code: u.invalid_string,
            message: a.message
          }), n.dirty();
        }
      else a.kind === "regex" ? (a.regex.lastIndex = 0, a.regex.test(e.data) || (s = this._getOrReturnCtx(e, s), f(s, {
        validation: "regex",
        code: u.invalid_string,
        message: a.message
      }), n.dirty())) : a.kind === "trim" ? e.data = e.data.trim() : a.kind === "includes" ? e.data.includes(a.value, a.position) || (s = this._getOrReturnCtx(e, s), f(s, {
        code: u.invalid_string,
        validation: { includes: a.value, position: a.position },
        message: a.message
      }), n.dirty()) : a.kind === "toLowerCase" ? e.data = e.data.toLowerCase() : a.kind === "toUpperCase" ? e.data = e.data.toUpperCase() : a.kind === "startsWith" ? e.data.startsWith(a.value) || (s = this._getOrReturnCtx(e, s), f(s, {
        code: u.invalid_string,
        validation: { startsWith: a.value },
        message: a.message
      }), n.dirty()) : a.kind === "endsWith" ? e.data.endsWith(a.value) || (s = this._getOrReturnCtx(e, s), f(s, {
        code: u.invalid_string,
        validation: { endsWith: a.value },
        message: a.message
      }), n.dirty()) : a.kind === "datetime" ? rn(a).test(e.data) || (s = this._getOrReturnCtx(e, s), f(s, {
        code: u.invalid_string,
        validation: "datetime",
        message: a.message
      }), n.dirty()) : a.kind === "ip" ? nn(e.data, a.version) || (s = this._getOrReturnCtx(e, s), f(s, {
        validation: "ip",
        code: u.invalid_string,
        message: a.message
      }), n.dirty()) : k.assertNever(a);
    return { status: n.value, value: e.data };
  }
  _regex(e, r, n) {
    return this.refinement((s) => e.test(s), {
      validation: r,
      code: u.invalid_string,
      ...h.errToObj(n)
    });
  }
  _addCheck(e) {
    return new P({
      ...this._def,
      checks: [...this._def.checks, e]
    });
  }
  email(e) {
    return this._addCheck({ kind: "email", ...h.errToObj(e) });
  }
  url(e) {
    return this._addCheck({ kind: "url", ...h.errToObj(e) });
  }
  emoji(e) {
    return this._addCheck({ kind: "emoji", ...h.errToObj(e) });
  }
  uuid(e) {
    return this._addCheck({ kind: "uuid", ...h.errToObj(e) });
  }
  cuid(e) {
    return this._addCheck({ kind: "cuid", ...h.errToObj(e) });
  }
  cuid2(e) {
    return this._addCheck({ kind: "cuid2", ...h.errToObj(e) });
  }
  ulid(e) {
    return this._addCheck({ kind: "ulid", ...h.errToObj(e) });
  }
  ip(e) {
    return this._addCheck({ kind: "ip", ...h.errToObj(e) });
  }
  datetime(e) {
    var r;
    return typeof e == "string" ? this._addCheck({
      kind: "datetime",
      precision: null,
      offset: !1,
      message: e
    }) : this._addCheck({
      kind: "datetime",
      precision: typeof (e == null ? void 0 : e.precision) > "u" ? null : e == null ? void 0 : e.precision,
      offset: (r = e == null ? void 0 : e.offset) !== null && r !== void 0 ? r : !1,
      ...h.errToObj(e == null ? void 0 : e.message)
    });
  }
  regex(e, r) {
    return this._addCheck({
      kind: "regex",
      regex: e,
      ...h.errToObj(r)
    });
  }
  includes(e, r) {
    return this._addCheck({
      kind: "includes",
      value: e,
      position: r == null ? void 0 : r.position,
      ...h.errToObj(r == null ? void 0 : r.message)
    });
  }
  startsWith(e, r) {
    return this._addCheck({
      kind: "startsWith",
      value: e,
      ...h.errToObj(r)
    });
  }
  endsWith(e, r) {
    return this._addCheck({
      kind: "endsWith",
      value: e,
      ...h.errToObj(r)
    });
  }
  min(e, r) {
    return this._addCheck({
      kind: "min",
      value: e,
      ...h.errToObj(r)
    });
  }
  max(e, r) {
    return this._addCheck({
      kind: "max",
      value: e,
      ...h.errToObj(r)
    });
  }
  length(e, r) {
    return this._addCheck({
      kind: "length",
      value: e,
      ...h.errToObj(r)
    });
  }
  /**
   * @deprecated Use z.string().min(1) instead.
   * @see {@link ZodString.min}
   */
  nonempty(e) {
    return this.min(1, h.errToObj(e));
  }
  trim() {
    return new P({
      ...this._def,
      checks: [...this._def.checks, { kind: "trim" }]
    });
  }
  toLowerCase() {
    return new P({
      ...this._def,
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    });
  }
  toUpperCase() {
    return new P({
      ...this._def,
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    });
  }
  get isDatetime() {
    return !!this._def.checks.find((e) => e.kind === "datetime");
  }
  get isEmail() {
    return !!this._def.checks.find((e) => e.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((e) => e.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((e) => e.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((e) => e.kind === "uuid");
  }
  get isCUID() {
    return !!this._def.checks.find((e) => e.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((e) => e.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((e) => e.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((e) => e.kind === "ip");
  }
  get minLength() {
    let e = null;
    for (const r of this._def.checks)
      r.kind === "min" && (e === null || r.value > e) && (e = r.value);
    return e;
  }
  get maxLength() {
    let e = null;
    for (const r of this._def.checks)
      r.kind === "max" && (e === null || r.value < e) && (e = r.value);
    return e;
  }
}
P.create = (t) => {
  var e;
  return new P({
    checks: [],
    typeName: m.ZodString,
    coerce: (e = t == null ? void 0 : t.coerce) !== null && e !== void 0 ? e : !1,
    ...g(t)
  });
};
function sn(t, e) {
  const r = (t.toString().split(".")[1] || "").length, n = (e.toString().split(".")[1] || "").length, s = r > n ? r : n, a = parseInt(t.toFixed(s).replace(".", "")), i = parseInt(e.toFixed(s).replace(".", ""));
  return a % i / Math.pow(10, s);
}
class K extends v {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte, this.step = this.multipleOf;
  }
  _parse(e) {
    if (this._def.coerce && (e.data = Number(e.data)), this._getType(e) !== l.number) {
      const a = this._getOrReturnCtx(e);
      return f(a, {
        code: u.invalid_type,
        expected: l.number,
        received: a.parsedType
      }), y;
    }
    let n;
    const s = new R();
    for (const a of this._def.checks)
      a.kind === "int" ? k.isInteger(e.data) || (n = this._getOrReturnCtx(e, n), f(n, {
        code: u.invalid_type,
        expected: "integer",
        received: "float",
        message: a.message
      }), s.dirty()) : a.kind === "min" ? (a.inclusive ? e.data < a.value : e.data <= a.value) && (n = this._getOrReturnCtx(e, n), f(n, {
        code: u.too_small,
        minimum: a.value,
        type: "number",
        inclusive: a.inclusive,
        exact: !1,
        message: a.message
      }), s.dirty()) : a.kind === "max" ? (a.inclusive ? e.data > a.value : e.data >= a.value) && (n = this._getOrReturnCtx(e, n), f(n, {
        code: u.too_big,
        maximum: a.value,
        type: "number",
        inclusive: a.inclusive,
        exact: !1,
        message: a.message
      }), s.dirty()) : a.kind === "multipleOf" ? sn(e.data, a.value) !== 0 && (n = this._getOrReturnCtx(e, n), f(n, {
        code: u.not_multiple_of,
        multipleOf: a.value,
        message: a.message
      }), s.dirty()) : a.kind === "finite" ? Number.isFinite(e.data) || (n = this._getOrReturnCtx(e, n), f(n, {
        code: u.not_finite,
        message: a.message
      }), s.dirty()) : k.assertNever(a);
    return { status: s.value, value: e.data };
  }
  gte(e, r) {
    return this.setLimit("min", e, !0, h.toString(r));
  }
  gt(e, r) {
    return this.setLimit("min", e, !1, h.toString(r));
  }
  lte(e, r) {
    return this.setLimit("max", e, !0, h.toString(r));
  }
  lt(e, r) {
    return this.setLimit("max", e, !1, h.toString(r));
  }
  setLimit(e, r, n, s) {
    return new K({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: e,
          value: r,
          inclusive: n,
          message: h.toString(s)
        }
      ]
    });
  }
  _addCheck(e) {
    return new K({
      ...this._def,
      checks: [...this._def.checks, e]
    });
  }
  int(e) {
    return this._addCheck({
      kind: "int",
      message: h.toString(e)
    });
  }
  positive(e) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !1,
      message: h.toString(e)
    });
  }
  negative(e) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !1,
      message: h.toString(e)
    });
  }
  nonpositive(e) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !0,
      message: h.toString(e)
    });
  }
  nonnegative(e) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !0,
      message: h.toString(e)
    });
  }
  multipleOf(e, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: e,
      message: h.toString(r)
    });
  }
  finite(e) {
    return this._addCheck({
      kind: "finite",
      message: h.toString(e)
    });
  }
  safe(e) {
    return this._addCheck({
      kind: "min",
      inclusive: !0,
      value: Number.MIN_SAFE_INTEGER,
      message: h.toString(e)
    })._addCheck({
      kind: "max",
      inclusive: !0,
      value: Number.MAX_SAFE_INTEGER,
      message: h.toString(e)
    });
  }
  get minValue() {
    let e = null;
    for (const r of this._def.checks)
      r.kind === "min" && (e === null || r.value > e) && (e = r.value);
    return e;
  }
  get maxValue() {
    let e = null;
    for (const r of this._def.checks)
      r.kind === "max" && (e === null || r.value < e) && (e = r.value);
    return e;
  }
  get isInt() {
    return !!this._def.checks.find((e) => e.kind === "int" || e.kind === "multipleOf" && k.isInteger(e.value));
  }
  get isFinite() {
    let e = null, r = null;
    for (const n of this._def.checks) {
      if (n.kind === "finite" || n.kind === "int" || n.kind === "multipleOf")
        return !0;
      n.kind === "min" ? (r === null || n.value > r) && (r = n.value) : n.kind === "max" && (e === null || n.value < e) && (e = n.value);
    }
    return Number.isFinite(r) && Number.isFinite(e);
  }
}
K.create = (t) => new K({
  checks: [],
  typeName: m.ZodNumber,
  coerce: (t == null ? void 0 : t.coerce) || !1,
  ...g(t)
});
class H extends v {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte;
  }
  _parse(e) {
    if (this._def.coerce && (e.data = BigInt(e.data)), this._getType(e) !== l.bigint) {
      const a = this._getOrReturnCtx(e);
      return f(a, {
        code: u.invalid_type,
        expected: l.bigint,
        received: a.parsedType
      }), y;
    }
    let n;
    const s = new R();
    for (const a of this._def.checks)
      a.kind === "min" ? (a.inclusive ? e.data < a.value : e.data <= a.value) && (n = this._getOrReturnCtx(e, n), f(n, {
        code: u.too_small,
        type: "bigint",
        minimum: a.value,
        inclusive: a.inclusive,
        message: a.message
      }), s.dirty()) : a.kind === "max" ? (a.inclusive ? e.data > a.value : e.data >= a.value) && (n = this._getOrReturnCtx(e, n), f(n, {
        code: u.too_big,
        type: "bigint",
        maximum: a.value,
        inclusive: a.inclusive,
        message: a.message
      }), s.dirty()) : a.kind === "multipleOf" ? e.data % a.value !== BigInt(0) && (n = this._getOrReturnCtx(e, n), f(n, {
        code: u.not_multiple_of,
        multipleOf: a.value,
        message: a.message
      }), s.dirty()) : k.assertNever(a);
    return { status: s.value, value: e.data };
  }
  gte(e, r) {
    return this.setLimit("min", e, !0, h.toString(r));
  }
  gt(e, r) {
    return this.setLimit("min", e, !1, h.toString(r));
  }
  lte(e, r) {
    return this.setLimit("max", e, !0, h.toString(r));
  }
  lt(e, r) {
    return this.setLimit("max", e, !1, h.toString(r));
  }
  setLimit(e, r, n, s) {
    return new H({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: e,
          value: r,
          inclusive: n,
          message: h.toString(s)
        }
      ]
    });
  }
  _addCheck(e) {
    return new H({
      ...this._def,
      checks: [...this._def.checks, e]
    });
  }
  positive(e) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !1,
      message: h.toString(e)
    });
  }
  negative(e) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !1,
      message: h.toString(e)
    });
  }
  nonpositive(e) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !0,
      message: h.toString(e)
    });
  }
  nonnegative(e) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !0,
      message: h.toString(e)
    });
  }
  multipleOf(e, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: e,
      message: h.toString(r)
    });
  }
  get minValue() {
    let e = null;
    for (const r of this._def.checks)
      r.kind === "min" && (e === null || r.value > e) && (e = r.value);
    return e;
  }
  get maxValue() {
    let e = null;
    for (const r of this._def.checks)
      r.kind === "max" && (e === null || r.value < e) && (e = r.value);
    return e;
  }
}
H.create = (t) => {
  var e;
  return new H({
    checks: [],
    typeName: m.ZodBigInt,
    coerce: (e = t == null ? void 0 : t.coerce) !== null && e !== void 0 ? e : !1,
    ...g(t)
  });
};
class fe extends v {
  _parse(e) {
    if (this._def.coerce && (e.data = !!e.data), this._getType(e) !== l.boolean) {
      const n = this._getOrReturnCtx(e);
      return f(n, {
        code: u.invalid_type,
        expected: l.boolean,
        received: n.parsedType
      }), y;
    }
    return N(e.data);
  }
}
fe.create = (t) => new fe({
  typeName: m.ZodBoolean,
  coerce: (t == null ? void 0 : t.coerce) || !1,
  ...g(t)
});
class G extends v {
  _parse(e) {
    if (this._def.coerce && (e.data = new Date(e.data)), this._getType(e) !== l.date) {
      const a = this._getOrReturnCtx(e);
      return f(a, {
        code: u.invalid_type,
        expected: l.date,
        received: a.parsedType
      }), y;
    }
    if (isNaN(e.data.getTime())) {
      const a = this._getOrReturnCtx(e);
      return f(a, {
        code: u.invalid_date
      }), y;
    }
    const n = new R();
    let s;
    for (const a of this._def.checks)
      a.kind === "min" ? e.data.getTime() < a.value && (s = this._getOrReturnCtx(e, s), f(s, {
        code: u.too_small,
        message: a.message,
        inclusive: !0,
        exact: !1,
        minimum: a.value,
        type: "date"
      }), n.dirty()) : a.kind === "max" ? e.data.getTime() > a.value && (s = this._getOrReturnCtx(e, s), f(s, {
        code: u.too_big,
        message: a.message,
        inclusive: !0,
        exact: !1,
        maximum: a.value,
        type: "date"
      }), n.dirty()) : k.assertNever(a);
    return {
      status: n.value,
      value: new Date(e.data.getTime())
    };
  }
  _addCheck(e) {
    return new G({
      ...this._def,
      checks: [...this._def.checks, e]
    });
  }
  min(e, r) {
    return this._addCheck({
      kind: "min",
      value: e.getTime(),
      message: h.toString(r)
    });
  }
  max(e, r) {
    return this._addCheck({
      kind: "max",
      value: e.getTime(),
      message: h.toString(r)
    });
  }
  get minDate() {
    let e = null;
    for (const r of this._def.checks)
      r.kind === "min" && (e === null || r.value > e) && (e = r.value);
    return e != null ? new Date(e) : null;
  }
  get maxDate() {
    let e = null;
    for (const r of this._def.checks)
      r.kind === "max" && (e === null || r.value < e) && (e = r.value);
    return e != null ? new Date(e) : null;
  }
}
G.create = (t) => new G({
  checks: [],
  coerce: (t == null ? void 0 : t.coerce) || !1,
  typeName: m.ZodDate,
  ...g(t)
});
class Ae extends v {
  _parse(e) {
    if (this._getType(e) !== l.symbol) {
      const n = this._getOrReturnCtx(e);
      return f(n, {
        code: u.invalid_type,
        expected: l.symbol,
        received: n.parsedType
      }), y;
    }
    return N(e.data);
  }
}
Ae.create = (t) => new Ae({
  typeName: m.ZodSymbol,
  ...g(t)
});
class he extends v {
  _parse(e) {
    if (this._getType(e) !== l.undefined) {
      const n = this._getOrReturnCtx(e);
      return f(n, {
        code: u.invalid_type,
        expected: l.undefined,
        received: n.parsedType
      }), y;
    }
    return N(e.data);
  }
}
he.create = (t) => new he({
  typeName: m.ZodUndefined,
  ...g(t)
});
class pe extends v {
  _parse(e) {
    if (this._getType(e) !== l.null) {
      const n = this._getOrReturnCtx(e);
      return f(n, {
        code: u.invalid_type,
        expected: l.null,
        received: n.parsedType
      }), y;
    }
    return N(e.data);
  }
}
pe.create = (t) => new pe({
  typeName: m.ZodNull,
  ...g(t)
});
class se extends v {
  constructor() {
    super(...arguments), this._any = !0;
  }
  _parse(e) {
    return N(e.data);
  }
}
se.create = (t) => new se({
  typeName: m.ZodAny,
  ...g(t)
});
class F extends v {
  constructor() {
    super(...arguments), this._unknown = !0;
  }
  _parse(e) {
    return N(e.data);
  }
}
F.create = (t) => new F({
  typeName: m.ZodUnknown,
  ...g(t)
});
class z extends v {
  _parse(e) {
    const r = this._getOrReturnCtx(e);
    return f(r, {
      code: u.invalid_type,
      expected: l.never,
      received: r.parsedType
    }), y;
  }
}
z.create = (t) => new z({
  typeName: m.ZodNever,
  ...g(t)
});
class Ce extends v {
  _parse(e) {
    if (this._getType(e) !== l.undefined) {
      const n = this._getOrReturnCtx(e);
      return f(n, {
        code: u.invalid_type,
        expected: l.void,
        received: n.parsedType
      }), y;
    }
    return N(e.data);
  }
}
Ce.create = (t) => new Ce({
  typeName: m.ZodVoid,
  ...g(t)
});
class L extends v {
  _parse(e) {
    const { ctx: r, status: n } = this._processInputParams(e), s = this._def;
    if (r.parsedType !== l.array)
      return f(r, {
        code: u.invalid_type,
        expected: l.array,
        received: r.parsedType
      }), y;
    if (s.exactLength !== null) {
      const i = r.data.length > s.exactLength.value, o = r.data.length < s.exactLength.value;
      (i || o) && (f(r, {
        code: i ? u.too_big : u.too_small,
        minimum: o ? s.exactLength.value : void 0,
        maximum: i ? s.exactLength.value : void 0,
        type: "array",
        inclusive: !0,
        exact: !0,
        message: s.exactLength.message
      }), n.dirty());
    }
    if (s.minLength !== null && r.data.length < s.minLength.value && (f(r, {
      code: u.too_small,
      minimum: s.minLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: s.minLength.message
    }), n.dirty()), s.maxLength !== null && r.data.length > s.maxLength.value && (f(r, {
      code: u.too_big,
      maximum: s.maxLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: s.maxLength.message
    }), n.dirty()), r.common.async)
      return Promise.all([...r.data].map((i, o) => s.type._parseAsync(new $(r, i, r.path, o)))).then((i) => R.mergeArray(n, i));
    const a = [...r.data].map((i, o) => s.type._parseSync(new $(r, i, r.path, o)));
    return R.mergeArray(n, a);
  }
  get element() {
    return this._def.type;
  }
  min(e, r) {
    return new L({
      ...this._def,
      minLength: { value: e, message: h.toString(r) }
    });
  }
  max(e, r) {
    return new L({
      ...this._def,
      maxLength: { value: e, message: h.toString(r) }
    });
  }
  length(e, r) {
    return new L({
      ...this._def,
      exactLength: { value: e, message: h.toString(r) }
    });
  }
  nonempty(e) {
    return this.min(1, e);
  }
}
L.create = (t, e) => new L({
  type: t,
  minLength: null,
  maxLength: null,
  exactLength: null,
  typeName: m.ZodArray,
  ...g(e)
});
function ee(t) {
  if (t instanceof x) {
    const e = {};
    for (const r in t.shape) {
      const n = t.shape[r];
      e[r] = M.create(ee(n));
    }
    return new x({
      ...t._def,
      shape: () => e
    });
  } else return t instanceof L ? new L({
    ...t._def,
    type: ee(t.element)
  }) : t instanceof M ? M.create(ee(t.unwrap())) : t instanceof J ? J.create(ee(t.unwrap())) : t instanceof U ? U.create(t.items.map((e) => ee(e))) : t;
}
class x extends v {
  constructor() {
    super(...arguments), this._cached = null, this.nonstrict = this.passthrough, this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const e = this._def.shape(), r = k.objectKeys(e);
    return this._cached = { shape: e, keys: r };
  }
  _parse(e) {
    if (this._getType(e) !== l.object) {
      const d = this._getOrReturnCtx(e);
      return f(d, {
        code: u.invalid_type,
        expected: l.object,
        received: d.parsedType
      }), y;
    }
    const { status: n, ctx: s } = this._processInputParams(e), { shape: a, keys: i } = this._getCached(), o = [];
    if (!(this._def.catchall instanceof z && this._def.unknownKeys === "strip"))
      for (const d in s.data)
        i.includes(d) || o.push(d);
    const c = [];
    for (const d of i) {
      const p = a[d], T = s.data[d];
      c.push({
        key: { status: "valid", value: d },
        value: p._parse(new $(s, T, s.path, d)),
        alwaysSet: d in s.data
      });
    }
    if (this._def.catchall instanceof z) {
      const d = this._def.unknownKeys;
      if (d === "passthrough")
        for (const p of o)
          c.push({
            key: { status: "valid", value: p },
            value: { status: "valid", value: s.data[p] }
          });
      else if (d === "strict")
        o.length > 0 && (f(s, {
          code: u.unrecognized_keys,
          keys: o
        }), n.dirty());
      else if (d !== "strip") throw new Error("Internal ZodObject error: invalid unknownKeys value.");
    } else {
      const d = this._def.catchall;
      for (const p of o) {
        const T = s.data[p];
        c.push({
          key: { status: "valid", value: p },
          value: d._parse(
            new $(s, T, s.path, p)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: p in s.data
        });
      }
    }
    return s.common.async ? Promise.resolve().then(async () => {
      const d = [];
      for (const p of c) {
        const T = await p.key;
        d.push({
          key: T,
          value: await p.value,
          alwaysSet: p.alwaysSet
        });
      }
      return d;
    }).then((d) => R.mergeObjectSync(n, d)) : R.mergeObjectSync(n, c);
  }
  get shape() {
    return this._def.shape();
  }
  strict(e) {
    return h.errToObj, new x({
      ...this._def,
      unknownKeys: "strict",
      ...e !== void 0 ? {
        errorMap: (r, n) => {
          var s, a, i, o;
          const c = (i = (a = (s = this._def).errorMap) === null || a === void 0 ? void 0 : a.call(s, r, n).message) !== null && i !== void 0 ? i : n.defaultError;
          return r.code === "unrecognized_keys" ? {
            message: (o = h.errToObj(e).message) !== null && o !== void 0 ? o : c
          } : {
            message: c
          };
        }
      } : {}
    });
  }
  strip() {
    return new x({
      ...this._def,
      unknownKeys: "strip"
    });
  }
  passthrough() {
    return new x({
      ...this._def,
      unknownKeys: "passthrough"
    });
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(e) {
    return new x({
      ...this._def,
      shape: () => ({
        ...this._def.shape(),
        ...e
      })
    });
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(e) {
    return new x({
      unknownKeys: e._def.unknownKeys,
      catchall: e._def.catchall,
      shape: () => ({
        ...this._def.shape(),
        ...e._def.shape()
      }),
      typeName: m.ZodObject
    });
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(e, r) {
    return this.augment({ [e]: r });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(e) {
    return new x({
      ...this._def,
      catchall: e
    });
  }
  pick(e) {
    const r = {};
    return k.objectKeys(e).forEach((n) => {
      e[n] && this.shape[n] && (r[n] = this.shape[n]);
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  omit(e) {
    const r = {};
    return k.objectKeys(this.shape).forEach((n) => {
      e[n] || (r[n] = this.shape[n]);
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return ee(this);
  }
  partial(e) {
    const r = {};
    return k.objectKeys(this.shape).forEach((n) => {
      const s = this.shape[n];
      e && !e[n] ? r[n] = s : r[n] = s.optional();
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  required(e) {
    const r = {};
    return k.objectKeys(this.shape).forEach((n) => {
      if (e && !e[n])
        r[n] = this.shape[n];
      else {
        let a = this.shape[n];
        for (; a instanceof M; )
          a = a._def.innerType;
        r[n] = a;
      }
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  keyof() {
    return It(k.objectKeys(this.shape));
  }
}
x.create = (t, e) => new x({
  shape: () => t,
  unknownKeys: "strip",
  catchall: z.create(),
  typeName: m.ZodObject,
  ...g(e)
});
x.strictCreate = (t, e) => new x({
  shape: () => t,
  unknownKeys: "strict",
  catchall: z.create(),
  typeName: m.ZodObject,
  ...g(e)
});
x.lazycreate = (t, e) => new x({
  shape: t,
  unknownKeys: "strip",
  catchall: z.create(),
  typeName: m.ZodObject,
  ...g(e)
});
class me extends v {
  _parse(e) {
    const { ctx: r } = this._processInputParams(e), n = this._def.options;
    function s(a) {
      for (const o of a)
        if (o.result.status === "valid")
          return o.result;
      for (const o of a)
        if (o.result.status === "dirty")
          return r.common.issues.push(...o.ctx.common.issues), o.result;
      const i = a.map((o) => new j(o.ctx.common.issues));
      return f(r, {
        code: u.invalid_union,
        unionErrors: i
      }), y;
    }
    if (r.common.async)
      return Promise.all(n.map(async (a) => {
        const i = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        };
        return {
          result: await a._parseAsync({
            data: r.data,
            path: r.path,
            parent: i
          }),
          ctx: i
        };
      })).then(s);
    {
      let a;
      const i = [];
      for (const c of n) {
        const d = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        }, p = c._parseSync({
          data: r.data,
          path: r.path,
          parent: d
        });
        if (p.status === "valid")
          return p;
        p.status === "dirty" && !a && (a = { result: p, ctx: d }), d.common.issues.length && i.push(d.common.issues);
      }
      if (a)
        return r.common.issues.push(...a.ctx.common.issues), a.result;
      const o = i.map((c) => new j(c));
      return f(r, {
        code: u.invalid_union,
        unionErrors: o
      }), y;
    }
  }
  get options() {
    return this._def.options;
  }
}
me.create = (t, e) => new me({
  options: t,
  typeName: m.ZodUnion,
  ...g(e)
});
const Se = (t) => t instanceof ve ? Se(t.schema) : t instanceof D ? Se(t.innerType()) : t instanceof _e ? [t.value] : t instanceof X ? t.options : t instanceof we ? Object.keys(t.enum) : t instanceof ke ? Se(t._def.innerType) : t instanceof he ? [void 0] : t instanceof pe ? [null] : null;
class Ue extends v {
  _parse(e) {
    const { ctx: r } = this._processInputParams(e);
    if (r.parsedType !== l.object)
      return f(r, {
        code: u.invalid_type,
        expected: l.object,
        received: r.parsedType
      }), y;
    const n = this.discriminator, s = r.data[n], a = this.optionsMap.get(s);
    return a ? r.common.async ? a._parseAsync({
      data: r.data,
      path: r.path,
      parent: r
    }) : a._parseSync({
      data: r.data,
      path: r.path,
      parent: r
    }) : (f(r, {
      code: u.invalid_union_discriminator,
      options: Array.from(this.optionsMap.keys()),
      path: [n]
    }), y);
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(e, r, n) {
    const s = /* @__PURE__ */ new Map();
    for (const a of r) {
      const i = Se(a.shape[e]);
      if (!i)
        throw new Error(`A discriminator value for key \`${e}\` could not be extracted from all schema options`);
      for (const o of i) {
        if (s.has(o))
          throw new Error(`Discriminator property ${String(e)} has duplicate value ${String(o)}`);
        s.set(o, a);
      }
    }
    return new Ue({
      typeName: m.ZodDiscriminatedUnion,
      discriminator: e,
      options: r,
      optionsMap: s,
      ...g(n)
    });
  }
}
function Qe(t, e) {
  const r = W(t), n = W(e);
  if (t === e)
    return { valid: !0, data: t };
  if (r === l.object && n === l.object) {
    const s = k.objectKeys(e), a = k.objectKeys(t).filter((o) => s.indexOf(o) !== -1), i = { ...t, ...e };
    for (const o of a) {
      const c = Qe(t[o], e[o]);
      if (!c.valid)
        return { valid: !1 };
      i[o] = c.data;
    }
    return { valid: !0, data: i };
  } else if (r === l.array && n === l.array) {
    if (t.length !== e.length)
      return { valid: !1 };
    const s = [];
    for (let a = 0; a < t.length; a++) {
      const i = t[a], o = e[a], c = Qe(i, o);
      if (!c.valid)
        return { valid: !1 };
      s.push(c.data);
    }
    return { valid: !0, data: s };
  } else return r === l.date && n === l.date && +t == +e ? { valid: !0, data: t } : { valid: !1 };
}
class ye extends v {
  _parse(e) {
    const { status: r, ctx: n } = this._processInputParams(e), s = (a, i) => {
      if (Ye(a) || Ye(i))
        return y;
      const o = Qe(a.value, i.value);
      return o.valid ? ((Je(a) || Je(i)) && r.dirty(), { status: r.value, value: o.data }) : (f(n, {
        code: u.invalid_intersection_types
      }), y);
    };
    return n.common.async ? Promise.all([
      this._def.left._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      }),
      this._def.right._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      })
    ]).then(([a, i]) => s(a, i)) : s(this._def.left._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }), this._def.right._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }));
  }
}
ye.create = (t, e, r) => new ye({
  left: t,
  right: e,
  typeName: m.ZodIntersection,
  ...g(r)
});
class U extends v {
  _parse(e) {
    const { status: r, ctx: n } = this._processInputParams(e);
    if (n.parsedType !== l.array)
      return f(n, {
        code: u.invalid_type,
        expected: l.array,
        received: n.parsedType
      }), y;
    if (n.data.length < this._def.items.length)
      return f(n, {
        code: u.too_small,
        minimum: this._def.items.length,
        inclusive: !0,
        exact: !1,
        type: "array"
      }), y;
    !this._def.rest && n.data.length > this._def.items.length && (f(n, {
      code: u.too_big,
      maximum: this._def.items.length,
      inclusive: !0,
      exact: !1,
      type: "array"
    }), r.dirty());
    const a = [...n.data].map((i, o) => {
      const c = this._def.items[o] || this._def.rest;
      return c ? c._parse(new $(n, i, n.path, o)) : null;
    }).filter((i) => !!i);
    return n.common.async ? Promise.all(a).then((i) => R.mergeArray(r, i)) : R.mergeArray(r, a);
  }
  get items() {
    return this._def.items;
  }
  rest(e) {
    return new U({
      ...this._def,
      rest: e
    });
  }
}
U.create = (t, e) => {
  if (!Array.isArray(t))
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  return new U({
    items: t,
    typeName: m.ZodTuple,
    rest: null,
    ...g(e)
  });
};
class ge extends v {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(e) {
    const { status: r, ctx: n } = this._processInputParams(e);
    if (n.parsedType !== l.object)
      return f(n, {
        code: u.invalid_type,
        expected: l.object,
        received: n.parsedType
      }), y;
    const s = [], a = this._def.keyType, i = this._def.valueType;
    for (const o in n.data)
      s.push({
        key: a._parse(new $(n, o, n.path, o)),
        value: i._parse(new $(n, n.data[o], n.path, o))
      });
    return n.common.async ? R.mergeObjectAsync(r, s) : R.mergeObjectSync(r, s);
  }
  get element() {
    return this._def.valueType;
  }
  static create(e, r, n) {
    return r instanceof v ? new ge({
      keyType: e,
      valueType: r,
      typeName: m.ZodRecord,
      ...g(n)
    }) : new ge({
      keyType: P.create(),
      valueType: e,
      typeName: m.ZodRecord,
      ...g(r)
    });
  }
}
class Ze extends v {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(e) {
    const { status: r, ctx: n } = this._processInputParams(e);
    if (n.parsedType !== l.map)
      return f(n, {
        code: u.invalid_type,
        expected: l.map,
        received: n.parsedType
      }), y;
    const s = this._def.keyType, a = this._def.valueType, i = [...n.data.entries()].map(([o, c], d) => ({
      key: s._parse(new $(n, o, n.path, [d, "key"])),
      value: a._parse(new $(n, c, n.path, [d, "value"]))
    }));
    if (n.common.async) {
      const o = /* @__PURE__ */ new Map();
      return Promise.resolve().then(async () => {
        for (const c of i) {
          const d = await c.key, p = await c.value;
          if (d.status === "aborted" || p.status === "aborted")
            return y;
          (d.status === "dirty" || p.status === "dirty") && r.dirty(), o.set(d.value, p.value);
        }
        return { status: r.value, value: o };
      });
    } else {
      const o = /* @__PURE__ */ new Map();
      for (const c of i) {
        const d = c.key, p = c.value;
        if (d.status === "aborted" || p.status === "aborted")
          return y;
        (d.status === "dirty" || p.status === "dirty") && r.dirty(), o.set(d.value, p.value);
      }
      return { status: r.value, value: o };
    }
  }
}
Ze.create = (t, e, r) => new Ze({
  valueType: e,
  keyType: t,
  typeName: m.ZodMap,
  ...g(r)
});
class Y extends v {
  _parse(e) {
    const { status: r, ctx: n } = this._processInputParams(e);
    if (n.parsedType !== l.set)
      return f(n, {
        code: u.invalid_type,
        expected: l.set,
        received: n.parsedType
      }), y;
    const s = this._def;
    s.minSize !== null && n.data.size < s.minSize.value && (f(n, {
      code: u.too_small,
      minimum: s.minSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: s.minSize.message
    }), r.dirty()), s.maxSize !== null && n.data.size > s.maxSize.value && (f(n, {
      code: u.too_big,
      maximum: s.maxSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: s.maxSize.message
    }), r.dirty());
    const a = this._def.valueType;
    function i(c) {
      const d = /* @__PURE__ */ new Set();
      for (const p of c) {
        if (p.status === "aborted")
          return y;
        p.status === "dirty" && r.dirty(), d.add(p.value);
      }
      return { status: r.value, value: d };
    }
    const o = [...n.data.values()].map((c, d) => a._parse(new $(n, c, n.path, d)));
    return n.common.async ? Promise.all(o).then((c) => i(c)) : i(o);
  }
  min(e, r) {
    return new Y({
      ...this._def,
      minSize: { value: e, message: h.toString(r) }
    });
  }
  max(e, r) {
    return new Y({
      ...this._def,
      maxSize: { value: e, message: h.toString(r) }
    });
  }
  size(e, r) {
    return this.min(e, r).max(e, r);
  }
  nonempty(e) {
    return this.min(1, e);
  }
}
Y.create = (t, e) => new Y({
  valueType: t,
  minSize: null,
  maxSize: null,
  typeName: m.ZodSet,
  ...g(e)
});
class re extends v {
  constructor() {
    super(...arguments), this.validate = this.implement;
  }
  _parse(e) {
    const { ctx: r } = this._processInputParams(e);
    if (r.parsedType !== l.function)
      return f(r, {
        code: u.invalid_type,
        expected: l.function,
        received: r.parsedType
      }), y;
    function n(o, c) {
      return Re({
        data: o,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          Ie(),
          de
        ].filter((d) => !!d),
        issueData: {
          code: u.invalid_arguments,
          argumentsError: c
        }
      });
    }
    function s(o, c) {
      return Re({
        data: o,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          Ie(),
          de
        ].filter((d) => !!d),
        issueData: {
          code: u.invalid_return_type,
          returnTypeError: c
        }
      });
    }
    const a = { errorMap: r.common.contextualErrorMap }, i = r.data;
    if (this._def.returns instanceof ae) {
      const o = this;
      return N(async function(...c) {
        const d = new j([]), p = await o._def.args.parseAsync(c, a).catch((b) => {
          throw d.addIssue(n(c, b)), d;
        }), T = await Reflect.apply(i, this, p);
        return await o._def.returns._def.type.parseAsync(T, a).catch((b) => {
          throw d.addIssue(s(T, b)), d;
        });
      });
    } else {
      const o = this;
      return N(function(...c) {
        const d = o._def.args.safeParse(c, a);
        if (!d.success)
          throw new j([n(c, d.error)]);
        const p = Reflect.apply(i, this, d.data), T = o._def.returns.safeParse(p, a);
        if (!T.success)
          throw new j([s(p, T.error)]);
        return T.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...e) {
    return new re({
      ...this._def,
      args: U.create(e).rest(F.create())
    });
  }
  returns(e) {
    return new re({
      ...this._def,
      returns: e
    });
  }
  implement(e) {
    return this.parse(e);
  }
  strictImplement(e) {
    return this.parse(e);
  }
  static create(e, r, n) {
    return new re({
      args: e || U.create([]).rest(F.create()),
      returns: r || F.create(),
      typeName: m.ZodFunction,
      ...g(n)
    });
  }
}
class ve extends v {
  get schema() {
    return this._def.getter();
  }
  _parse(e) {
    const { ctx: r } = this._processInputParams(e);
    return this._def.getter()._parse({ data: r.data, path: r.path, parent: r });
  }
}
ve.create = (t, e) => new ve({
  getter: t,
  typeName: m.ZodLazy,
  ...g(e)
});
class _e extends v {
  _parse(e) {
    if (e.data !== this._def.value) {
      const r = this._getOrReturnCtx(e);
      return f(r, {
        received: r.data,
        code: u.invalid_literal,
        expected: this._def.value
      }), y;
    }
    return { status: "valid", value: e.data };
  }
  get value() {
    return this._def.value;
  }
}
_e.create = (t, e) => new _e({
  value: t,
  typeName: m.ZodLiteral,
  ...g(e)
});
function It(t, e) {
  return new X({
    values: t,
    typeName: m.ZodEnum,
    ...g(e)
  });
}
class X extends v {
  _parse(e) {
    if (typeof e.data != "string") {
      const r = this._getOrReturnCtx(e), n = this._def.values;
      return f(r, {
        expected: k.joinValues(n),
        received: r.parsedType,
        code: u.invalid_type
      }), y;
    }
    if (this._def.values.indexOf(e.data) === -1) {
      const r = this._getOrReturnCtx(e), n = this._def.values;
      return f(r, {
        received: r.data,
        code: u.invalid_enum_value,
        options: n
      }), y;
    }
    return N(e.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const e = {};
    for (const r of this._def.values)
      e[r] = r;
    return e;
  }
  get Values() {
    const e = {};
    for (const r of this._def.values)
      e[r] = r;
    return e;
  }
  get Enum() {
    const e = {};
    for (const r of this._def.values)
      e[r] = r;
    return e;
  }
  extract(e) {
    return X.create(e);
  }
  exclude(e) {
    return X.create(this.options.filter((r) => !e.includes(r)));
  }
}
X.create = It;
class we extends v {
  _parse(e) {
    const r = k.getValidEnumValues(this._def.values), n = this._getOrReturnCtx(e);
    if (n.parsedType !== l.string && n.parsedType !== l.number) {
      const s = k.objectValues(r);
      return f(n, {
        expected: k.joinValues(s),
        received: n.parsedType,
        code: u.invalid_type
      }), y;
    }
    if (r.indexOf(e.data) === -1) {
      const s = k.objectValues(r);
      return f(n, {
        received: n.data,
        code: u.invalid_enum_value,
        options: s
      }), y;
    }
    return N(e.data);
  }
  get enum() {
    return this._def.values;
  }
}
we.create = (t, e) => new we({
  values: t,
  typeName: m.ZodNativeEnum,
  ...g(e)
});
class ae extends v {
  unwrap() {
    return this._def.type;
  }
  _parse(e) {
    const { ctx: r } = this._processInputParams(e);
    if (r.parsedType !== l.promise && r.common.async === !1)
      return f(r, {
        code: u.invalid_type,
        expected: l.promise,
        received: r.parsedType
      }), y;
    const n = r.parsedType === l.promise ? r.data : Promise.resolve(r.data);
    return N(n.then((s) => this._def.type.parseAsync(s, {
      path: r.path,
      errorMap: r.common.contextualErrorMap
    })));
  }
}
ae.create = (t, e) => new ae({
  type: t,
  typeName: m.ZodPromise,
  ...g(e)
});
class D extends v {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === m.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(e) {
    const { status: r, ctx: n } = this._processInputParams(e), s = this._def.effect || null, a = {
      addIssue: (i) => {
        f(n, i), i.fatal ? r.abort() : r.dirty();
      },
      get path() {
        return n.path;
      }
    };
    if (a.addIssue = a.addIssue.bind(a), s.type === "preprocess") {
      const i = s.transform(n.data, a);
      return n.common.issues.length ? {
        status: "dirty",
        value: n.data
      } : n.common.async ? Promise.resolve(i).then((o) => this._def.schema._parseAsync({
        data: o,
        path: n.path,
        parent: n
      })) : this._def.schema._parseSync({
        data: i,
        path: n.path,
        parent: n
      });
    }
    if (s.type === "refinement") {
      const i = (o) => {
        const c = s.refinement(o, a);
        if (n.common.async)
          return Promise.resolve(c);
        if (c instanceof Promise)
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        return o;
      };
      if (n.common.async === !1) {
        const o = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return o.status === "aborted" ? y : (o.status === "dirty" && r.dirty(), i(o.value), { status: r.value, value: o.value });
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((o) => o.status === "aborted" ? y : (o.status === "dirty" && r.dirty(), i(o.value).then(() => ({ status: r.value, value: o.value }))));
    }
    if (s.type === "transform")
      if (n.common.async === !1) {
        const i = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        if (!le(i))
          return i;
        const o = s.transform(i.value, a);
        if (o instanceof Promise)
          throw new Error("Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.");
        return { status: r.value, value: o };
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((i) => le(i) ? Promise.resolve(s.transform(i.value, a)).then((o) => ({ status: r.value, value: o })) : i);
    k.assertNever(s);
  }
}
D.create = (t, e, r) => new D({
  schema: t,
  typeName: m.ZodEffects,
  effect: e,
  ...g(r)
});
D.createWithPreprocess = (t, e, r) => new D({
  schema: e,
  effect: { type: "preprocess", transform: t },
  typeName: m.ZodEffects,
  ...g(r)
});
class M extends v {
  _parse(e) {
    return this._getType(e) === l.undefined ? N(void 0) : this._def.innerType._parse(e);
  }
  unwrap() {
    return this._def.innerType;
  }
}
M.create = (t, e) => new M({
  innerType: t,
  typeName: m.ZodOptional,
  ...g(e)
});
class J extends v {
  _parse(e) {
    return this._getType(e) === l.null ? N(null) : this._def.innerType._parse(e);
  }
  unwrap() {
    return this._def.innerType;
  }
}
J.create = (t, e) => new J({
  innerType: t,
  typeName: m.ZodNullable,
  ...g(e)
});
class ke extends v {
  _parse(e) {
    const { ctx: r } = this._processInputParams(e);
    let n = r.data;
    return r.parsedType === l.undefined && (n = this._def.defaultValue()), this._def.innerType._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
}
ke.create = (t, e) => new ke({
  innerType: t,
  typeName: m.ZodDefault,
  defaultValue: typeof e.default == "function" ? e.default : () => e.default,
  ...g(e)
});
class Pe extends v {
  _parse(e) {
    const { ctx: r } = this._processInputParams(e), n = {
      ...r,
      common: {
        ...r.common,
        issues: []
      }
    }, s = this._def.innerType._parse({
      data: n.data,
      path: n.path,
      parent: {
        ...n
      }
    });
    return Ne(s) ? s.then((a) => ({
      status: "valid",
      value: a.status === "valid" ? a.value : this._def.catchValue({
        get error() {
          return new j(n.common.issues);
        },
        input: n.data
      })
    })) : {
      status: "valid",
      value: s.status === "valid" ? s.value : this._def.catchValue({
        get error() {
          return new j(n.common.issues);
        },
        input: n.data
      })
    };
  }
  removeCatch() {
    return this._def.innerType;
  }
}
Pe.create = (t, e) => new Pe({
  innerType: t,
  typeName: m.ZodCatch,
  catchValue: typeof e.catch == "function" ? e.catch : () => e.catch,
  ...g(e)
});
class je extends v {
  _parse(e) {
    if (this._getType(e) !== l.nan) {
      const n = this._getOrReturnCtx(e);
      return f(n, {
        code: u.invalid_type,
        expected: l.nan,
        received: n.parsedType
      }), y;
    }
    return { status: "valid", value: e.data };
  }
}
je.create = (t) => new je({
  typeName: m.ZodNaN,
  ...g(t)
});
const an = Symbol("zod_brand");
class Rt extends v {
  _parse(e) {
    const { ctx: r } = this._processInputParams(e), n = r.data;
    return this._def.type._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  unwrap() {
    return this._def.type;
  }
}
class xe extends v {
  _parse(e) {
    const { status: r, ctx: n } = this._processInputParams(e);
    if (n.common.async)
      return (async () => {
        const a = await this._def.in._parseAsync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return a.status === "aborted" ? y : a.status === "dirty" ? (r.dirty(), Ot(a.value)) : this._def.out._parseAsync({
          data: a.value,
          path: n.path,
          parent: n
        });
      })();
    {
      const s = this._def.in._parseSync({
        data: n.data,
        path: n.path,
        parent: n
      });
      return s.status === "aborted" ? y : s.status === "dirty" ? (r.dirty(), {
        status: "dirty",
        value: s.value
      }) : this._def.out._parseSync({
        data: s.value,
        path: n.path,
        parent: n
      });
    }
  }
  static create(e, r) {
    return new xe({
      in: e,
      out: r,
      typeName: m.ZodPipeline
    });
  }
}
class Le extends v {
  _parse(e) {
    const r = this._def.innerType._parse(e);
    return le(r) && (r.value = Object.freeze(r.value)), r;
  }
}
Le.create = (t, e) => new Le({
  innerType: t,
  typeName: m.ZodReadonly,
  ...g(e)
});
const Nt = (t, e = {}, r) => t ? se.create().superRefine((n, s) => {
  var a, i;
  if (!t(n)) {
    const o = typeof e == "function" ? e(n) : typeof e == "string" ? { message: e } : e, c = (i = (a = o.fatal) !== null && a !== void 0 ? a : r) !== null && i !== void 0 ? i : !0, d = typeof o == "string" ? { message: o } : o;
    s.addIssue({ code: "custom", ...d, fatal: c });
  }
}) : se.create(), on = {
  object: x.lazycreate
};
var m;
(function(t) {
  t.ZodString = "ZodString", t.ZodNumber = "ZodNumber", t.ZodNaN = "ZodNaN", t.ZodBigInt = "ZodBigInt", t.ZodBoolean = "ZodBoolean", t.ZodDate = "ZodDate", t.ZodSymbol = "ZodSymbol", t.ZodUndefined = "ZodUndefined", t.ZodNull = "ZodNull", t.ZodAny = "ZodAny", t.ZodUnknown = "ZodUnknown", t.ZodNever = "ZodNever", t.ZodVoid = "ZodVoid", t.ZodArray = "ZodArray", t.ZodObject = "ZodObject", t.ZodUnion = "ZodUnion", t.ZodDiscriminatedUnion = "ZodDiscriminatedUnion", t.ZodIntersection = "ZodIntersection", t.ZodTuple = "ZodTuple", t.ZodRecord = "ZodRecord", t.ZodMap = "ZodMap", t.ZodSet = "ZodSet", t.ZodFunction = "ZodFunction", t.ZodLazy = "ZodLazy", t.ZodLiteral = "ZodLiteral", t.ZodEnum = "ZodEnum", t.ZodEffects = "ZodEffects", t.ZodNativeEnum = "ZodNativeEnum", t.ZodOptional = "ZodOptional", t.ZodNullable = "ZodNullable", t.ZodDefault = "ZodDefault", t.ZodCatch = "ZodCatch", t.ZodPromise = "ZodPromise", t.ZodBranded = "ZodBranded", t.ZodPipeline = "ZodPipeline", t.ZodReadonly = "ZodReadonly";
})(m || (m = {}));
const cn = (t, e = {
  message: `Input not instance of ${t.name}`
}) => Nt((r) => r instanceof t, e), At = P.create, Ct = K.create, un = je.create, dn = H.create, Zt = fe.create, ln = G.create, fn = Ae.create, hn = he.create, pn = pe.create, mn = se.create, yn = F.create, gn = z.create, vn = Ce.create, _n = L.create, wn = x.create, kn = x.strictCreate, xn = me.create, bn = Ue.create, Tn = ye.create, En = U.create, Sn = ge.create, On = Ze.create, In = Y.create, Rn = re.create, Nn = ve.create, An = _e.create, Cn = X.create, Zn = we.create, Pn = ae.create, ot = D.create, jn = M.create, Ln = J.create, Dn = D.createWithPreprocess, Vn = xe.create, $n = () => At().optional(), Un = () => Ct().optional(), Mn = () => Zt().optional(), zn = {
  string: (t) => P.create({ ...t, coerce: !0 }),
  number: (t) => K.create({ ...t, coerce: !0 }),
  boolean: (t) => fe.create({
    ...t,
    coerce: !0
  }),
  bigint: (t) => H.create({ ...t, coerce: !0 }),
  date: (t) => G.create({ ...t, coerce: !0 })
}, Wn = y;
var E = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: de,
  setErrorMap: Hr,
  getErrorMap: Ie,
  makeIssue: Re,
  EMPTY_PATH: Xr,
  addIssueToContext: f,
  ParseStatus: R,
  INVALID: y,
  DIRTY: Ot,
  OK: N,
  isAborted: Ye,
  isDirty: Je,
  isValid: le,
  isAsync: Ne,
  get util() {
    return k;
  },
  get objectUtil() {
    return Ge;
  },
  ZodParsedType: l,
  getParsedType: W,
  ZodType: v,
  ZodString: P,
  ZodNumber: K,
  ZodBigInt: H,
  ZodBoolean: fe,
  ZodDate: G,
  ZodSymbol: Ae,
  ZodUndefined: he,
  ZodNull: pe,
  ZodAny: se,
  ZodUnknown: F,
  ZodNever: z,
  ZodVoid: Ce,
  ZodArray: L,
  ZodObject: x,
  ZodUnion: me,
  ZodDiscriminatedUnion: Ue,
  ZodIntersection: ye,
  ZodTuple: U,
  ZodRecord: ge,
  ZodMap: Ze,
  ZodSet: Y,
  ZodFunction: re,
  ZodLazy: ve,
  ZodLiteral: _e,
  ZodEnum: X,
  ZodNativeEnum: we,
  ZodPromise: ae,
  ZodEffects: D,
  ZodTransformer: D,
  ZodOptional: M,
  ZodNullable: J,
  ZodDefault: ke,
  ZodCatch: Pe,
  ZodNaN: je,
  BRAND: an,
  ZodBranded: Rt,
  ZodPipeline: xe,
  ZodReadonly: Le,
  custom: Nt,
  Schema: v,
  ZodSchema: v,
  late: on,
  get ZodFirstPartyTypeKind() {
    return m;
  },
  coerce: zn,
  any: mn,
  array: _n,
  bigint: dn,
  boolean: Zt,
  date: ln,
  discriminatedUnion: bn,
  effect: ot,
  enum: Cn,
  function: Rn,
  instanceof: cn,
  intersection: Tn,
  lazy: Nn,
  literal: An,
  map: On,
  nan: un,
  nativeEnum: Zn,
  never: gn,
  null: pn,
  nullable: Ln,
  number: Ct,
  object: wn,
  oboolean: Mn,
  onumber: Un,
  optional: jn,
  ostring: $n,
  pipeline: Vn,
  preprocess: Dn,
  promise: Pn,
  record: Sn,
  set: In,
  strictObject: kn,
  string: At,
  symbol: fn,
  transformer: ot,
  tuple: En,
  undefined: hn,
  union: xn,
  unknown: yn,
  void: vn,
  NEVER: Wn,
  ZodIssueCode: u,
  quotelessJson: Kr,
  ZodError: j
});
E.object({
  version: E.number(),
  lastUpdated: E.string().datetime(),
  namespaces: E.record(E.object({
    name: E.string(),
    version: E.number(),
    createdAt: E.string().datetime(),
    updatedAt: E.string().datetime(),
    keys: E.array(E.string())
  }))
});
const De = E.object({
  schemaVersion: E.number(),
  metadata: E.object({
    createdAt: E.string().datetime(),
    updatedAt: E.string().datetime(),
    namespace: E.string(),
    encrypted: E.boolean().optional(),
    compressed: E.boolean().optional(),
    ttl: E.number().optional()
  }),
  data: E.unknown()
});
class Z {
  constructor() {
    this.listeners = /* @__PURE__ */ new Map();
  }
  static new() {
    return Z.instance || (Z.instance = new Z()), Z.instance;
  }
  getFullKey(e, r) {
    return `${e}:${r}`;
  }
  notifyListeners(e, r, n) {
    const s = this.getFullKey(e, r);
    (this.listeners.get(s) || /* @__PURE__ */ new Set()).forEach((i) => i({ namespace: e, key: r, value: n }));
  }
  async set(e, r, n) {
    const s = De.parse(n);
    localStorage.setItem(this.getFullKey(e, r), _.stringify(s)), this.notifyListeners(e, r, s.data);
  }
  async getRaw(e, r) {
    const n = localStorage.getItem(this.getFullKey(e, r));
    if (!n) return;
    const s = _.parse(n);
    return De.parse(s);
  }
  async get(e, r) {
    const n = await this.getRaw(e, r);
    return n == null ? void 0 : n.data;
  }
  async has(e, r) {
    return localStorage.getItem(this.getFullKey(e, r)) !== null;
  }
  async delete(e, r) {
    const n = await this.has(e, r);
    return n && (localStorage.removeItem(this.getFullKey(e, r)), this.notifyListeners(e, r, void 0)), n;
  }
  async clear(e) {
    e ? Object.keys(localStorage).filter((n) => n.startsWith(`${e}:`)).forEach((n) => localStorage.removeItem(n)) : localStorage.clear(), this.listeners.clear();
  }
  async listNamespaces() {
    const e = /* @__PURE__ */ new Set();
    return Object.keys(localStorage).forEach((r) => {
      const [n] = r.split(":");
      e.add(n);
    }), Array.from(e);
  }
  async listKeys(e) {
    return Object.keys(localStorage).filter((r) => r.startsWith(`${e}:`)).map((r) => r.replace(`${e}:`, ""));
  }
  watch(e, r) {
    const n = this.getFullKey(e, r);
    return {
      on: (s, a) => s !== "change" ? () => {
      } : (this.listeners.has(n) || this.listeners.set(n, /* @__PURE__ */ new Set()), this.listeners.get(n).add(a), () => {
        var i;
        return (i = this.listeners.get(n)) == null ? void 0 : i.delete(a);
      }),
      once: (s, a) => {
        if (s !== "change") return () => {
        };
        const i = (o) => {
          var c;
          a(o), (c = this.listeners.get(n)) == null || c.delete(i);
        };
        return this.listeners.has(n) || this.listeners.set(n, /* @__PURE__ */ new Set()), this.listeners.get(n).add(i), () => {
          var o;
          return (o = this.listeners.get(n)) == null ? void 0 : o.delete(i);
        };
      },
      off: (s, a) => {
        var i;
        s === "change" && ((i = this.listeners.get(n)) == null || i.delete(a));
      }
    };
  }
}
const Bn = {
  version: { major: 1, minor: 0, patch: 0 },
  api: {
    id: "browser-store",
    capabilities: /* @__PURE__ */ new Set(["permanent", "structured", "watch", "namespace"]),
    async init() {
      try {
        return O(void 0);
      } catch (t) {
        return w({
          kind: "storage",
          message: t instanceof Error ? t.message : "Unknown error",
          cause: t
        });
      }
    },
    async set(t, e, r, n) {
      try {
        const s = Z.new(), a = await s.getRaw(t, e), i = (a == null ? void 0 : a.metadata.createdAt) || (/* @__PURE__ */ new Date()).toISOString(), o = (/* @__PURE__ */ new Date()).toISOString(), c = {
          schemaVersion: 1,
          metadata: {
            createdAt: i,
            updatedAt: o,
            namespace: t,
            encrypted: n == null ? void 0 : n.encrypt,
            compressed: n == null ? void 0 : n.compress,
            ttl: n == null ? void 0 : n.ttl
          },
          data: r
        };
        return await s.set(t, e, c), O(void 0);
      } catch (s) {
        return w({
          kind: "storage",
          message: s instanceof Error ? s.message : "Unknown error",
          cause: s
        });
      }
    },
    async get(t, e) {
      try {
        const r = Z.new();
        return O(await r.get(t, e));
      } catch (r) {
        return w({
          kind: "storage",
          message: r instanceof Error ? r.message : "Unknown error",
          cause: r
        });
      }
    },
    async has(t, e) {
      try {
        const r = Z.new();
        return O(await r.has(t, e));
      } catch (r) {
        return w({
          kind: "storage",
          message: r instanceof Error ? r.message : "Unknown error",
          cause: r
        });
      }
    },
    async remove(t, e) {
      try {
        const r = Z.new();
        return O(await r.delete(t, e));
      } catch (r) {
        return w({
          kind: "storage",
          message: r instanceof Error ? r.message : "Unknown error",
          cause: r
        });
      }
    },
    async clear(t) {
      try {
        return await Z.new().clear(t), O(void 0);
      } catch (e) {
        return w({
          kind: "storage",
          message: e instanceof Error ? e.message : "Unknown error",
          cause: e
        });
      }
    },
    async listNamespaces() {
      try {
        const t = Z.new();
        return O(await t.listNamespaces());
      } catch (t) {
        return w({
          kind: "storage",
          message: t instanceof Error ? t.message : "Unknown error",
          cause: t
        });
      }
    },
    async listKeys(t) {
      try {
        const e = Z.new();
        return O(await e.listKeys(t));
      } catch (e) {
        return w({
          kind: "storage",
          message: e instanceof Error ? e.message : "Unknown error",
          cause: e
        });
      }
    },
    watch(t, e) {
      return Z.new().watch(t, e);
    }
  }
}, Kn = {
  v1: Bn
};
class Ve extends Qt {
  constructor(e) {
    super(e);
  }
  /**
   * Create a new Store or load the existing store with the path.
   *
   * @example
   * ```typescript
   * import { Store } from '@tauri-apps/api/store';
   * const store = await Store.load('store.json');
   * ```
   *
   * @param path Path to save the store in `app_data_dir`
   * @param options Store configuration options
   */
  static async load(e, r) {
    const n = await S("plugin:store|load", {
      path: e,
      ...r
    });
    return new Ve(n);
  }
  /**
   * Gets an already loaded store.
   *
   * If the store is not loaded, returns `null`. In this case you must {@link Store.load load} it.
   *
   * This function is more useful when you already know the store is loaded
   * and just need to access its instance. Prefer {@link Store.load} otherwise.
   *
   * @example
   * ```typescript
   * import { Store } from '@tauri-apps/api/store';
   * let store = await Store.get('store.json');
   * if (!store) {
   *   store = await Store.load('store.json');
   * }
   * ```
   *
   * @param path Path of the store.
   */
  static async get(e) {
    return await S("plugin:store|get_store", { path: e }).then((r) => r ? new Ve(r) : null);
  }
  async set(e, r) {
    await S("plugin:store|set", {
      rid: this.rid,
      key: e,
      value: r
    });
  }
  async get(e) {
    const [r, n] = await S("plugin:store|get", {
      rid: this.rid,
      key: e
    });
    return n ? r : void 0;
  }
  async has(e) {
    return await S("plugin:store|has", {
      rid: this.rid,
      key: e
    });
  }
  async delete(e) {
    return await S("plugin:store|delete", {
      rid: this.rid,
      key: e
    });
  }
  async clear() {
    await S("plugin:store|clear", { rid: this.rid });
  }
  async reset() {
    await S("plugin:store|reset", { rid: this.rid });
  }
  async keys() {
    return await S("plugin:store|keys", { rid: this.rid });
  }
  async values() {
    return await S("plugin:store|values", { rid: this.rid });
  }
  async entries() {
    return await S("plugin:store|entries", { rid: this.rid });
  }
  async length() {
    return await S("plugin:store|length", { rid: this.rid });
  }
  async reload() {
    await S("plugin:store|reload", { rid: this.rid });
  }
  async save() {
    await S("plugin:store|save", { rid: this.rid });
  }
  async onKeyChange(e, r) {
    return await Oe("store://change", (n) => {
      n.payload.resourceId === this.rid && n.payload.key === e && r(n.payload.exists ? n.payload.value : void 0);
    });
  }
  async onChange(e) {
    return await Oe("store://change", (r) => {
      r.payload.resourceId === this.rid && e(r.payload.key, r.payload.exists ? r.payload.value : void 0);
    });
  }
}
const Hn = `${window.location.host}.hoppscotch.store`;
class A {
  constructor() {
    this.store = null, this.listeners = /* @__PURE__ */ new Map(), this.data = {};
  }
  static new() {
    return A.instance || (A.instance = new A()), A.instance;
  }
  async init() {
    if (!this.store) {
      this.store = await Ve.load(Hn);
      const e = await this.store.get("data");
      this.data = e ?? {}, this.store.onChange((r, n) => {
        n && (this.data = n, this.notifyListeners());
      });
    }
  }
  notifyListeners() {
    var e;
    for (const [r, n] of this.listeners.entries()) {
      const [s, a] = r.split(":"), i = (e = this.data[s]) == null ? void 0 : e[a];
      n.forEach(
        (o) => o({
          namespace: s,
          key: a,
          value: i == null ? void 0 : i.data
        })
      );
    }
  }
  async set(e, r, n) {
    if (!this.store) throw new Error("Store not initialized");
    const s = De.parse(n);
    this.data[e] = this.data[e] || {}, this.data[e][r] = s, await this.store.set("data", this.data), await this.store.save();
  }
  async getRaw(e, r) {
    var a;
    const n = (a = this.data[e]) == null ? void 0 : a[r];
    return n ? De.parse(n) : void 0;
  }
  async get(e, r) {
    const n = await this.getRaw(e, r);
    return n == null ? void 0 : n.data;
  }
  async has(e, r) {
    var n;
    return !!((n = this.data[e]) != null && n[r]);
  }
  async delete(e, r) {
    var n;
    if (!this.store) throw new Error("Store not initialized");
    return (n = this.data[e]) != null && n[r] ? (delete this.data[e][r], Object.keys(this.data[e]).length === 0 && delete this.data[e], await this.store.set("data", this.data), await this.store.save(), !0) : !1;
  }
  async clear(e) {
    if (!this.store) throw new Error("Store not initialized");
    e ? delete this.data[e] : this.data = {}, await this.store.set("data", this.data), await this.store.save();
  }
  async listNamespaces() {
    return Object.keys(this.data);
  }
  async listKeys(e) {
    return Object.keys(this.data[e] || {});
  }
  watch(e, r) {
    const n = `${e}:${r}`;
    return {
      on: (s, a) => s !== "change" ? () => {
      } : (this.listeners.has(n) || this.listeners.set(n, /* @__PURE__ */ new Set()), this.listeners.get(n).add(a), () => {
        var i;
        return (i = this.listeners.get(n)) == null ? void 0 : i.delete(a);
      }),
      once: (s, a) => {
        if (s !== "change") return () => {
        };
        const i = (o) => {
          var c;
          a(o), (c = this.listeners.get(n)) == null || c.delete(i);
        };
        return this.listeners.has(n) || this.listeners.set(n, /* @__PURE__ */ new Set()), this.listeners.get(n).add(i), () => {
          var o;
          return (o = this.listeners.get(n)) == null ? void 0 : o.delete(i);
        };
      },
      off: (s, a) => {
        var i;
        s === "change" && ((i = this.listeners.get(n)) == null || i.delete(a));
      }
    };
  }
  async close() {
    this.store && (await this.store.close(), this.store = null, this.data = {}, this.listeners.clear());
  }
}
const Xn = {
  version: { major: 1, minor: 0, patch: 0 },
  api: {
    id: "tauri-store",
    capabilities: /* @__PURE__ */ new Set(["permanent", "structured", "watch", "namespace", "secure"]),
    async init() {
      try {
        return await A.new().init(), O(void 0);
      } catch (t) {
        return w({
          kind: "storage",
          message: t instanceof Error ? t.message : "Unknown error",
          cause: t
        });
      }
    },
    async set(t, e, r, n) {
      try {
        const s = A.new(), a = await s.getRaw(t, e), i = (a == null ? void 0 : a.metadata.createdAt) || (/* @__PURE__ */ new Date()).toISOString(), o = (/* @__PURE__ */ new Date()).toISOString(), c = {
          schemaVersion: 1,
          metadata: {
            createdAt: i,
            updatedAt: o,
            namespace: t,
            encrypted: n == null ? void 0 : n.encrypt,
            compressed: n == null ? void 0 : n.compress,
            ttl: n == null ? void 0 : n.ttl
          },
          data: r
        };
        return await s.set(t, e, c), O(void 0);
      } catch (s) {
        return w({
          kind: "storage",
          message: s instanceof Error ? s.message : "Unknown error",
          cause: s
        });
      }
    },
    async get(t, e) {
      try {
        const r = A.new();
        return O(await r.get(t, e));
      } catch (r) {
        return w({
          kind: "storage",
          message: r instanceof Error ? r.message : "Unknown error",
          cause: r
        });
      }
    },
    async has(t, e) {
      try {
        const r = A.new();
        return O(await r.has(t, e));
      } catch (r) {
        return w({
          kind: "storage",
          message: r instanceof Error ? r.message : "Unknown error",
          cause: r
        });
      }
    },
    async remove(t, e) {
      try {
        const r = A.new();
        return O(await r.delete(t, e));
      } catch (r) {
        return w({
          kind: "storage",
          message: r instanceof Error ? r.message : "Unknown error",
          cause: r
        });
      }
    },
    async clear(t) {
      try {
        return await A.new().clear(t), O(void 0);
      } catch (e) {
        return w({
          kind: "storage",
          message: e instanceof Error ? e.message : "Unknown error",
          cause: e
        });
      }
    },
    async listNamespaces() {
      try {
        const t = A.new();
        return O(await t.listNamespaces());
      } catch (t) {
        return w({
          kind: "storage",
          message: t instanceof Error ? t.message : "Unknown error",
          cause: t
        });
      }
    },
    async listKeys(t) {
      try {
        const e = A.new();
        return O(await e.listKeys(t));
      } catch (e) {
        return w({
          kind: "storage",
          message: e instanceof Error ? e.message : "Unknown error",
          cause: e
        });
      }
    },
    watch(t, e) {
      return A.new().watch(t, e);
    }
  }
}, Fn = {
  v1: Xn
};
function es() {
  return window.__KERNEL_MODE__ || "web";
}
function ts(t) {
  if (t === "desktop") {
    const e = {
      info: {
        name: "desktop-kernel",
        version: { major: 1, minor: 0, patch: 0 },
        capabilities: ["basic-io"]
      },
      io: rr.v1.api,
      relay: mr.v1.api,
      store: Fn.v1.api
    };
    return window.__KERNEL__ = e, e;
  } else {
    const e = {
      info: {
        name: "web-kernel",
        version: { major: 1, minor: 0, patch: 0 },
        capabilities: ["basic-io"]
      },
      io: Ft.v1.api,
      relay: lr.v1.api,
      store: Kn.v1.api
    };
    return window.__KERNEL__ = e, e;
  }
}
export {
  dt as MediaType,
  lt as body,
  qn as content,
  es as getKernelMode,
  ts as initKernel
};

import { HoppModule } from "@hoppscotch/common/modules"
import * as E from "fp-ts/Either"
import { z } from "zod"
import { def } from "@platform/auth/desktop"
import type { Router } from "vue-router"
import { getService } from "@hoppscotch/common/modules/dioc"
import { KernelInterceptorService } from "@hoppscotch/common/services/kernel-interceptor.service"
import { parseBodyAsJSON } from "@hoppscotch/common/helpers/functional/json"

const SiteProtectionExpectedResultSchema = z.object({
  name: z.literal("SITE_PROTECTION"),
  value: z.enum(["ENABLE", "DISABLE"]),
})

const getSiteProtectionStatus = async () => {
  try {
    const interceptorService = getService(KernelInterceptorService)

    const { response } = interceptorService.execute({
      id: Date.now(),
      url: `${import.meta.env.VITE_BACKEND_API_URL}/site/status`,
      version: "HTTP/1.1",
      method: "GET",
    })

    const responseData = await response

    if (E.isLeft(responseData)) {
      return E.left("REQUEST_FAILED")
    }

    const parsedData = parseBodyAsJSON(responseData.right.body)

    if (parsedData._tag === "None") {
      return E.left("PARSE_FAILED")
    }

    const result = SiteProtectionExpectedResultSchema.safeParse(
      parsedData.value
    )

    if (!result.success) {
      return E.left("SCHEMA_VALIDATION_FAILED")
    }

    return E.right(result.data)
  } catch (err) {
    console.error(err)
    return E.left("SOMETHING_WENT_WRONG")
  }
}

const whoIsAccessing = async () => {
  const probableUser = def.getProbableUser()

  if (probableUser) {
    await def.waitProbableLoginToConfirm()
    const confirmedUser = def.getCurrentUser()

    if (confirmedUser) return "AUTHENTICATED"
  }

  return "GUEST"
}

export const SiteProtectionModule: HoppModule = {
  onRouterInit(_, router) {
    def.getAuthEventsStream().subscribe(async (event) => {
      // if user logs out, check if site protection is enabled, if yes, redirect to login-required page
      if (event.event === "logout") {
        const siteProtectionStatus = await getSiteProtectionStatus()
        handleSiteProtectionResponse(siteProtectionStatus, router)
      }

      // if user logs in (on desktop that's through device-login)
      if (event.event === "login") {
        // navigate to home page on success
        router.replace("/")
      }
    })
  },

  async onBeforeRouteChange(to, _, router) {
    const who = await whoIsAccessing()

    if (to.meta.onlyGuest && who === "GUEST") return
    if (!to.meta.onlyGuest && who === "AUTHENTICATED") return

    if (to.meta.onlyGuest && who === "AUTHENTICATED") {
      router.replace("/")
      return
    }

    const siteProtectionStatus = await getSiteProtectionStatus()

    handleSiteProtectionResponse(siteProtectionStatus, router)
  },
}

const handleSiteProtectionResponse = (
  siteProtectionStatus: E.Either<
    string,
    z.infer<typeof SiteProtectionExpectedResultSchema>
  >,
  router: Router
) => {
  if (E.isLeft(siteProtectionStatus)) {
    // redirect to login-required page with error message
    return router.push({
      path: `/login-required`,
      query: {
        error: "site_protection.error_fetching_site_protection_status",
      },
    })
  }

  const siteProtectionValue = siteProtectionStatus.right.value

  if (siteProtectionValue === "ENABLE") {
    return router.push("/login-required")
  }

  return
}
